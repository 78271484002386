import { takeEvery, put, call } from 'redux-saga/effects'
// import { loginByToken, login } from "api/api-auth"
import * as actions from './auth.reducer'

// function* loginByTokenRequest() {
// 	try {
// 		const response:{data:any} = yield call(loginByToken)
// 		yield put(actions.setProfile(response.data))
// 	} catch (e) {
// 		console.log(e)
// 		window.location.href = "/login"
// 	}
// }

export default function* watchAuthSaga() {
	// yield takeEvery(actions.loginByTokenSaga, loginByTokenRequest)
}
