import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from './auth.reducer'

const useAuth = () => {
	const dispatch = useDispatch()
	const auth = useSelector((state:any) => {
		return state.authReducer
	})
	const setTrackAddress = useCallback((trackaddress:any)=>{
		dispatch(actions.setTrackAddress(trackaddress))
	}, [dispatch])

	const setHeaderType = useCallback((headerType:any)=>{
		dispatch(actions.setHeaderType(headerType))
	}, [dispatch])

	const setChainId = useCallback((chainId:any)=>{
		dispatch(actions.setChainId(chainId))
	}, [dispatch])

	const setServiceType = useCallback((type:any)=>{
		dispatch(actions.setServiceType(type))
	}, [dispatch])

	const setSidebarType = useCallback((type:any)=>{
		dispatch(actions.setSidebarType(type))
	}, [dispatch])

	const setSidebarToggle = useCallback((toggle:any)=>{
		dispatch(actions.setSidebarToggle(toggle))
	}, [dispatch])

	const setContractsLoaded = useCallback((flag:any)=>{
		dispatch(actions.setContractsLoaded(flag))
	}, [dispatch])

	return { auth, setTrackAddress, setHeaderType, setChainId, setServiceType, setSidebarType, setSidebarToggle, setContractsLoaded }
}

export default useAuth
