import React, { useEffect, useState } from 'react'
import AdminOverViewInfo from '../../components/AdminOverViewInfo'
import axios from 'axios'
import useAuth from 'rftxStore/auth/auth.hook'
import { useAlertError } from '../../hooks/useAlertError'
import { useNavigate } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import apiConfig from 'api/config'

// eslint-disable-next-line no-undef
const REACT_APP_OWNER = process.env.REACT_APP_OWNER || ''

const BACKEND_URL = `${apiConfig.host}/api/v1`

const AdminOverView = () => {
	const { setSidebarType, setHeaderType } = useAuth()
	const { address, isConnected } = useAccount()

	const [loadState, setLoadState] = useState({
		loadSummary: false,
		loadHolderData: false,
	})
	const [summaryData, setSummaryData] = useState({
		marketcap: 0,
		holders: 0,
		avgTokenHeld: 0,
		totalDividendsDistributed: 0,
		totalPartitionDistributed: 0,
		standardDivIndex: 0,
		total401kInfo: {
			total401kDeposit: 0,
			total401kMath: 0,
		},
	})
	const [holderLookup, setHolderLookup] = useState({
		tokenHeld: 0,
		reflection: {
			earned: 0,
			claimable: 0,
		},
		total401kDeposit: 0,
		total401kMatch: 0,
		feeTimer: 0,
		partitionEarned: 0,
	})

	const navigate = useNavigate()
	const alertError = useAlertError()

	useEffect(() => {
		setHeaderType(0)
		setSidebarType(3)
	}, [])

	useEffect(() => {
		if (isConnected) {
			setSidebarType(3)
			loadAdminData()
		} else if (isConnected == false) {
			navigate('/account')
		}
	}, [address, isConnected])

	const loadAdminData = async () => {
		if (address) {
			await checkAdmin()
			await loadSummaryData()
		} else {
			navigate('/account', { replace: false })
		}
	}

	const checkAdmin = async () => {
		if (address) {
			if (address.toLowerCase() !== REACT_APP_OWNER.toLowerCase()) {
				alertError(new Error('You are not owner'))
				navigate('/account', { replace: false })
			}
		} else {
			alertError(new Error('Please connect Metamask'))
		}
	}

	const loadSummaryData = async () => {
		try {
			const { data } = await axios(`${BACKEND_URL}/web3Api/getTokenSummary`)
			setSummaryData(data)
		} catch (err) {
			console.log(err)
		}
	}

	const checkHolderLookup = async (holderAddress) => {
		try {
			setLoadState((prev) => ({ ...prev, loadHolderData: true }))
			const { data } = await axios.get(
				`${BACKEND_URL}/web3Api/getHolderLookup`,
				{
					params: { address: holderAddress },
				}
			)
			setHolderLookup(data)
		} catch (err) {
			console.log(err)
		} finally {
			setLoadState((prev) => ({ ...prev, loadHolderData: false }))
		}
	}

	return (
		<AdminOverViewInfo
			summaryData={summaryData}
			checkHolderLookup={checkHolderLookup}
			holderLookup={holderLookup}
			loadState={loadState}
		/>
	)
}

export default AdminOverView
