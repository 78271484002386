import { Toaster } from 'react-hot-toast'
import { Web3Modal } from '@web3modal/react'
import { WagmiConfig } from 'wagmi'
import {ethereumClient, wagmiClient}  from './web3Client'
import './App.css'
import AppRoutes from 'routes'
import { AccountInfoProvider } from 'hooks/AccountInfoProvider'

// eslint-disable-next-line no-undef
if (!process.env.REACT_APP_PROJECT_ID)
	throw new Error('You need to provide REACT_APP_PROJECT_ID env variable')

const App = () => {

	return (
		<div className="App">
			<WagmiConfig client={wagmiClient}>
				<AccountInfoProvider>
					<AppRoutes />
				</AccountInfoProvider>
			</WagmiConfig>
			<Web3Modal
				style = {{zIndex:100}}
				themeMode="dark"
				// eslint-disable-next-line no-undef
				projectId={process.env.REACT_APP_PROJECT_ID}
				ethereumClient={ethereumClient}
			/>
			<Toaster
				position="top-center"
				toastOptions={{
					style: {
						fontSize: '17px',
					},
				}}
			/>
		</div>
	)
}

export default App
