import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useAccountInfo } from './AccountProvider'
import { Button, Checkbox, Col, Row } from 'antd'
import useAuth from 'rftxStore/auth/auth.hook'
import { getSupportTokens } from 'api/api-rftx'
import { useNetwork } from 'wagmi'

const  BalanceSection = () => {

	const { accountInfo, loadAccount } = useAccountInfo()
	const { chain } = useNetwork()
	const { setTrackAddress, auth:{trackAddress} } = useAuth()

	const [plainOptions, setPlainOptions] = useState([])
	const [explorerUrl, setExporlerUrl] = useState('https://bscscan.com')

	const changeTrackTokens = async (checkedValues: any) => {
		setTrackAddress(checkedValues)
	}

	useEffect(()=>{
		getSupportTokens().then(res=>{
			setPlainOptions(res.data)
			setTrackAddress([res.data[0].address])
			const mBlockExpUrl = 'https://testnet.bscscan.com/'
			setExporlerUrl(mBlockExpUrl)
		})

	}, [chain])


	return (
		<div>
			<div className='pb-4'>
				<Row>
					<Col span={12}>
						<label className="">Total Tracked Value</label>
					</Col>
					<Col span={12}>
						<span>$
							<NumberFormat
								value={accountInfo.totalBalance}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<label>RFX Account Value</label>
					</Col>
					<Col span={12}>
						<span>$
							<NumberFormat
								value={accountInfo.tokenValue}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
				</Row>
				{/* <Row>
					<Col span={12}>
						<label>401k Contribution</label>
					</Col>
					<Col span={12}>
						<span>$
							<NumberFormat
								value={accountInfo.four01kValue}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
				</Row> */}
			</div>
			<div className='pb-4'>
				<Row>
					<Col span={12}>
						<label>RFX Staked</label>
					</Col>
					<Col span={12}>
						<span>$
							<NumberFormat
								value={accountInfo.stakingBalance}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
						
				</Row>
			</div>
			<div className='pb-4'>
				<Row>
					<Col span={12}>
						<label>RFX in Wallet</label>
					</Col>
					<Col span = {12}>
						<span>
							<NumberFormat
								value={accountInfo.tokenBalance}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<label>Value</label>
					</Col>
					<Col span={12}>
						<span>$
							<NumberFormat
								value={accountInfo.mainTokenValue}
								displayType={'text'}
								thousandSeparator={true}
							/>
						</span>
					</Col>
				</Row>
				{/* <Row>
					<Col span={12}>
						<label>Reflections earned</label>
					</Col>
					<Col span={12}>
						<span>
							<NumberFormat
								value={accountInfo.reflection.earned}
								displayType={'text'}
								thousandSeparator={true}
							/>
							BUSD
						</span>
					</Col>
				</Row> */}
				{/* <Row>
					<Col span={12}>
						<label>Partition earned</label>
					</Col>
					<Col span={12}>
						<span>
							<NumberFormat
								value={accountInfo.partitionEarned}
								displayType={'text'}
								thousandSeparator={true}
							/>
										BUSD
						</span>
					</Col>
				</Row> */}
			</div>

			<div className='d-flex justify-content-center flex-column align-items-center mt-5'>
				<Button size={'middle'} onClick={() => loadAccount()}>
					Update Account Tracking
				</Button>
				<Checkbox.Group
					// options={plainOptions}
					value={ trackAddress }
					onChange={changeTrackTokens}
					style={{
						display: 'flex',
						paddingTop: '40px',
					}}
					className="AccountTrackingCheckBox"
				>
					{
						plainOptions.map((ele:any, index)=><Checkbox value={ele.address} key = {index}>{ele.symbol}</Checkbox>)
					}
				</Checkbox.Group>
			</div>
		</div>
	)
}

export default BalanceSection