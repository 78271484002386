import React, { useEffect } from 'react'
import { feeInfo } from '../../constants'
import useAuth from 'rftxStore/auth/auth.hook'
import BackToMain from '../BackToMain'
import './PlatformFees.css'

const PlatformFees = () => {
	const { setHeaderType } = useAuth()

	useEffect(() => {
		setHeaderType(3)
	}, [])

	return (
		<div className="fee-page px-5 position-relative">
			<BackToMain />
			<div className="fee-main w-100 d-col-center ">
				<label className="fee-title">
          Financial Services Platform Schedule of Fees
				</label>
				<div className="fee-items py-5">
					{feeInfo.map((item, index) => {
						return (
							<div key={index}>
								<div
									className="item-title"
									dangerouslySetInnerHTML={{ __html: item.title }}
								></div>
								<div
									className="item-description pt-2"
									dangerouslySetInnerHTML={{ __html: item.description }}
								></div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default PlatformFees
