import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaTwitter, FaTelegram, FaDiscord, FaYoutube, FaReddit } from 'react-icons/fa'
import { PoolLiveDataType, PoolType } from 'utils/types'
import { useSigner, useAccount } from 'wagmi'
import { forceStop, getPoolInfo, transferToPool, cancelPool, emergencyWithdrawByOwner } from 'web3Client/pool'
import { ethers } from 'ethers'
import { updatePoolLiveData } from 'api/api-pools'

// eslint-disable-next-line no-undef
const REACT_APP_OWNER = process.env.REACT_APP_OWNER || ''

interface Props {
    pool:PoolType
}

const PoolItem = React.memo(({ pool }: Props) => {

	const { data: signer } = useSigner()
	const { address } = useAccount()
	const [liveData, setLiveData] = useState<PoolLiveDataType>()
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(()=>{
		setLiveData(pool.live_data)
	}, [signer])

	const loadLiveData = async () => {
		setLoading(true)
		if(signer){
			const res = await getPoolInfo(pool.pool, pool.live_data, signer)
			setLiveData(res)
		}
		setLoading(false)
		updatePoolLiveData(pool.pool)
	}

	return (
		<div className="d-flex flex-column gap-2">
			<div className="d-flex gap-2 align-items-center justify-content-center">
				<label>{pool.pool}</label>
				{!pool.live_data.isRewardTokenTransfered &&
							(address?.toLowerCase() !==
							REACT_APP_OWNER.toLowerCase() ||
							(address.toLowerCase() ===
								REACT_APP_OWNER.toLowerCase() &&
								address.toLowerCase() ===
								pool.live_data.owner.toLowerCase())) && (
					<Button
						type='primary'
						className="border-btn"
						onClick={async () => {
							await transferToPool(pool.pool, signer)
							loadLiveData()
						}}
					>
						Transfer To Pool
					</Button>
				)}
			</div>
			<div className="row" style={{
				borderRadius: '10px',
				border: '5px solid #1f9e85',
				backgroundColor: '#cdf0ea'
			}}>
				<div className="col-md-9 col-12">
					<div className="d-flex justify-content-between">
						<div
							className="d-flex flex-column"
							style={{ fontSize: '20px' }}
						>
							<label>{pool.project_name}</label>
							<label>{pool.stake_token.symbol}</label>
						</div>
						<div
							className="font-bold"
							style={{ fontSize: '18px', fontWeight: 'bold' }}
						>
                            Pool APY: {pool.apy}%
						</div>
						<div
							className="social-items d-flex gap-1 justify-content-center align-items-center"
							style={{ height: '25px' }}
						>
							{pool.twitter !== '' && (
								<a href={pool.twitter} target={'_blank'} rel="noreferrer">
									<FaTwitter className="social-icon" />
								</a>
							)}

							{pool.telegram !== '' && (
								<a href={pool.telegram} target={'_blank'} rel="noreferrer">
									<FaTelegram className="social-icon" />
								</a>
							)}
							{pool.discord !== '' && (
								<a href={pool.discord} target={'_blank'} rel="noreferrer">
									<FaDiscord className="social-icon" />
								</a>
							)}

							{pool.youtube !== '' && (
								<a href={pool.youtube} target={'_blank'} rel="noreferrer">
									<FaYoutube className="social-icon" />
								</a>
							)}

							{pool.reddit !== '' && (
								<a target={'_blank'} href={pool.reddit} rel="noreferrer">
									<FaReddit className="social-icon" />
								</a>
							)}
						</div>
					</div>

					<div className="d-flex align-items-center">
						<img
							src={pool.logoImg}
							width={50}
							height={50}
							alt="logo"
						/>
						<div className="d-flex flex-column justify-content-center align-items-center text-center gap-2">
							<label>
								Tokens Staked: { pool.live_data.totalStakedAmount } / {pool.max_stakeable} 
							</label>
							<label>
                            Initial Lock period:
								{pool.lock_time === 0
									? '1 year'
									: pool.lock_time === 1
										? '180 days'
										: pool.lock_time === 2
											? '90 days'
											: '30 days'}
							</label>
							{ liveData && 
								<label>
									<strong>Time Remaining:</strong>
									{liveData.lifeTime.days} Days, {liveData.lifeTime.hours} Hours, {liveData.lifeTime.minutes} Minutes
								</label>
							}
							{address &&
                              address.toLowerCase() === REACT_APP_OWNER.toLowerCase() &&
                              !pool.live_data.isForceStopped && (
								<Button
									type='primary'
									className="force-stop-btn"
									onClick={async () =>{
										await forceStop(pool.pool, signer)
										loadLiveData()
									}}
								>
                                  Force Stop
								</Button>
							)}
						</div>
					</div>
				</div>
				{liveData && <div className="col-md-3 col-12 d-flex flex-column justify-content-center align-items-center gap-2">
					{liveData.isStopped ? (
						'Canceled'
					) : (
						<Button
							type='primary'
							className="border-btn w-100 text-nowrap"
							onClick={async () => {
								// handleCancelPool(pool.poolAddress)
								await cancelPool(pool.pool, signer)
								loadLiveData()
							}}
						>
                              Cancel Pool
						</Button>
					)}
					{(address?.toLowerCase() !==
                          REACT_APP_OWNER.toLowerCase() ||
                          (address.toLowerCase() ===
                            REACT_APP_OWNER.toLowerCase() &&
                            address.toLowerCase() ===
                            liveData.owner.toLowerCase())) && (
						<Button
							type='primary'
							className="border-btn w-100"
							onClick={async () => {
								// handleEmergencyWithdrawByOwner(pool.poolAddress)
								await emergencyWithdrawByOwner(pool.pool, signer)
								loadLiveData()
							}}
						>
                            Withdraw
						</Button>
					)}
					{
						pool.is_private && <div className='w-100 border border-2 border-danger text-center rounded text-danger fw-bold'>Private</div>

					}
				</div>}
			</div>
		</div>
	)
})

export default PoolItem