import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { PoolType } from 'utils/types'
import { useAccount, useSigner } from 'wagmi'
import { checkFSPPoolFactoryAdmin } from 'web3Client/account'
import { useNavigate } from 'react-router-dom'
import { useAlertError } from './useAlertError'
import * as PoolAPI from 'api/api-pools'


export type AdminPoolProviderContextTypes = {
    pools: any;
    fetchPools: any;
};

export const AdminPoolcontext = createContext<AdminPoolProviderContextTypes>({
	pools: undefined,
	fetchPools: undefined
})

export const AdminPoolProvider = (props:any)=>{

	const [pools, setPools] = useState<PoolType[]>([])
	const { address, isConnected } = useAccount()
	const navigate = useNavigate()
	const { data: signer } = useSigner()
	const alertError = useAlertError()

	useEffect(() => {
		if (address && signer) {
			loadAdminData()
			fetchCratedPools()
		} else if (isConnected == false) {
			navigate('/account', { replace: false })
		}
	}, [address, isConnected, signer])

	const loadAdminData = async () => {
		let isAdmin = false
		if (address) {
			isAdmin = await checkAdmin()
		}

		if(!isAdmin) navigate('/account', { replace: false })
	}

	const checkAdmin = async () => {
		try {
			return await checkFSPPoolFactoryAdmin(signer)
		} catch (err:any) {
			alertError(new Error(err.message))
		}
		return false
	}

	const fetchCratedPools = async () => {
		try {
			const { data } = await PoolAPI.getMyPools(address)
			setPools(data)
		} catch (err) {
			console.log(err)
		}
	}


	return (
		<AdminPoolcontext.Provider
			value={{
				pools: pools,
				fetchPools:fetchCratedPools
			}}
		>
			{props.children}
		</AdminPoolcontext.Provider>
	)
}

export const useAdminPool = () => useContext(AdminPoolcontext)