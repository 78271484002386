import React, { useEffect } from 'react'
import { Button, ButtonProps } from 'antd'
import useAuth from 'rftxStore/auth/auth.hook'
import { useNavigate } from 'react-router-dom'

interface Props extends ButtonProps {
    title:string;
    menuKey:number;
    onClick?:()=>void;
}

const MobileMenuItem = ({ title, menuKey, onClick, ...props }:Props) => {

	const { auth:{ sidebarType }, setSidebarType } = useAuth()
	const navigate = useNavigate()

	return (
		<Button 
			{...props} type='primary' size='large' className={`m-2 h-auto ${sidebarType == menuKey?'active-menu-item':''}`
			}
			onClick={()=>{
				if(props.href) navigate(props.href)
				setSidebarType(menuKey)
				if(onClick) onClick()
			}}
			data-bs-dismiss="offcanvas"
		>{title}</Button>
	)
}
export default MobileMenuItem