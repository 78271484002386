import React, { useEffect } from 'react'
import LogoImg from '../../assets/img/logo.png'
import './Header.css'
import { FaBars } from 'react-icons/fa'
import useAuth from 'rftxStore/auth/auth.hook'
import { useAlertError } from '../../hooks/useAlertError'
import { useNavigate } from 'react-router-dom'
import { Button, Layout, Space, Typography, Divider, Spin } from 'antd'
import { header_social_items } from '../../constants'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useNetwork } from 'wagmi'
import { CloseOutlined } from '@ant-design/icons'
import MobileMenuItem from './MobileMenuItem'
import { isAdmin } from 'api/api-users'
import { LoadingOutlined } from '@ant-design/icons'

// eslint-disable-next-line no-undef
const REACT_APP_OWNER = process.env.REACT_APP_OWNER || ''

const { Header } = Layout

const HeaderNavbar = (props) => {
	const { type, headerType } = props
	const { address, isConnected } = useAccount()
	const { open: walletOpen, close, isOpen } = useWeb3Modal()
	let navigate = useNavigate()
	const { auth:{ sidebarType }, setContractsLoaded, setSidebarType } = useAuth()
	const alertError = useAlertError()

	// const shortAddress = (address)?React.useMemo(() => `${address.slice(0, 6)}...${address.slice(-4)}`, [address]):''

	const toggleLogo = () => {
		const logo = document.querySelector('.HeaderLogoImg')
		logo.classList.toggle('toggleLogo')
	}

	// useEffect(()=>{
	// 	getContracts().then((res)=>{
	// 		setContracts(res.data)
	// 		setContractsLoaded(true)
	// 	})
	// }, [])

	const toAdmin = async () => {
		try {
			if (!isConnected) {
				throw new Error('Please connect Wallet')
			}
			const { data } = await isAdmin({ address })
			if (data.result == false) {
				throw new Error('You are not admin')
			}
			setSidebarType(4)
			navigate('/stakingpool')
		} catch (err) {
			alertError(new Error(err.message))
			navigate('/account', { replace: false })
		}
	}

	return (
		<>
			<Header className='d-sm-none h-auto p-0'>
				<Space className='bg-white w-100 d-flex justify-content-center position-sticky top-0' style={{ height:'36px', zIndex:'100'}}>
					{header_social_items.map((item, index) => {
						return (
							<a href={item.link} target={'_blank'} key={index} rel="noreferrer" className='text-success fs-4'>
								{item.icon}
							</a>
						)
					})}
				</Space>
				<Space className='ps-4 pe-4 pt-2 pb-2 d-flex justify-content-between'>
					<img
						src={LogoImg}
						height="50px"
						onClick={() => navigate('/', { replace: true })}
					/>
					<div>
						<FaBars
							className="ToggleBtn text-white "
							data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasLeft"
							aria-controls="offcanvasLeft"
						/>
						<div
							className="offcanvas offcanvas-start"
							tabIndex="-1"
							id="offcanvasLeft"
							aria-labelledby="offcanvasLeftLabel"
						
							style={{ backgroundColor:'#001529'}}
						>
							<div className="offcanvas-header">
								<img src={LogoImg} height={50} width="auto" />
								<Button
									className='d-flex align-items-center justify-content-center'
									size='large'
									type='primary'
									shape="circle"
									data-bs-dismiss="offcanvas"
									aria-label="Close"
								><CloseOutlined /></Button>
							</div>
							<div className='d-flex justify-content-center'>
								<Button type='primary' className='border-btn' onClick={walletOpen} style={{ whiteSpace:'nowrap', height:'auto'}}>
									{isOpen && !isConnected && (<div className='d-flex align-items-center gap-2 p-2'><Spin indicator={<LoadingOutlined style = {{ color:'white' }} />} /> <label>Connecting...</label></div>)}
									{isConnected && address && `${address.slice(0, 6)}...${address.slice(-4)}`}
									{!isConnected && !isOpen && 'Connect Wallet'}
								</Button>
							</div>
							<div className='w-100 d-flex gap-0 flex-column'>
								<MobileMenuItem href='/account' title='Account' menuKey={0} />
								<MobileMenuItem href="/401k" title='401k' menuKey={1} />
								<MobileMenuItem href='/staking' title='Staking' menuKey={2}/>
								<Divider/>
								<MobileMenuItem title='Admin' menuKey={4} onClick={toAdmin} />
								{sidebarType == 4 && <MobileMenuItem title='Staking pools' menuKey={4} />}
								<MobileMenuItem href='/disclaimers' title='Disclaimers' menuKey={9} />
								<MobileMenuItem href='/fees' title='Platform Fees' menuKey={10} />
								{address && address.toLowerCase() == REACT_APP_OWNER.toLowerCase() && (
									<>
										<MobileMenuItem href='/admin' title='Overview' menuKey={11} />
										<MobileMenuItem href='/requests' title='Withdrawal requests' menuKey={12} />
									</>
								)}


							</div>
						</div>
					</div>
				</Space>
				<Typography.Title  level={4} className='text-center text-white' >
					{type === 'admin' ? (
						<small>Admin</small>
					) : sidebarType === 0 || sidebarType === undefined ? (
						<small>Account</small>
					) : sidebarType === 1 ? (
						<small>401k</small>
					) : sidebarType === 2 ? (
						<small>Staking</small>
					) : sidebarType === 3 ? (
						<small>Overview</small>
					) : sidebarType === 4 ? (
						<small>Staking Pools</small>
					) : sidebarType === 5 ? (
						<small>Lending</small>
					) : sidebarType === 6 ? (
						<small>Borrowing</small>
					) : sidebarType === 7 ? (
						<small>Withdrawal Request</small>
					) : sidebarType === 8 ? (
						<small>Deposit</small>
					) : null
					}
				</Typography.Title>
			</Header>

			<Header
				style={{ height: 'auto', lineHeight: '32px', zIndex:'100' }}
				className="w-100 d-flex flex-column justify-content-center align-items-center d-none d-sm-block p-0 position-sticky top-0 bg-black"
			>
				<div className="header-social-items">
					{header_social_items.map((item, index) => {
						return (
							<a href={item.link} target={'_blank'} key={index} rel="noreferrer">
								{item.icon}
							</a>
						)
					})}
				</div>
				<div className="PageHeaderInRow">
					<img
						src={LogoImg}
						className="HeaderLogoImg"
						onClick={() => navigate('/', { replace: true })}
					/>
					<div className="HeaderText py-4 position-relative font-title text-uppercase">
						{headerType === 0 || headerType === 4
							? 'Financial Services Platform'
							: headerType === 1
								? '401k Withdrawal'
								: headerType === 2
									? 'FINANCIAL SERVICES PLATFORM UTILITIES'
									: headerType === 3
										? 'Financial Services Platform Fees'
										: null}
					</div>

					<Button type='primary' className='border-btn' onClick={walletOpen} style={{ whiteSpace:'nowrap', height:'auto'}}>
						{isOpen && !isConnected && (<div className='d-flex align-items-center gap-2 p-2'><Spin indicator={<LoadingOutlined style = {{ color:'white' }} />} /> <label>Connecting...</label></div>)}
						{isConnected && address && `${address.slice(0, 6)}...${address.slice(-4)}`}
						{!isConnected && !isOpen && 'Connect Wallet'}
					</Button>
				</div>

				{headerType === 0 ? (
					<span
						className="HeaderPageName font-title"
						style={{ marginLeft:'50%', transform:'translateX(-50%)'}}
						onClick={() => toggleLogo()}
					>
						{type === 'admin' ? (
							<small>Admin</small>
						) : sidebarType === 0 || sidebarType === undefined ? (
							<small>Account</small>
						) : sidebarType === 1 ? (
							<small>401k</small>
						) : sidebarType === 2 ? (
							<small>Staking</small>
						) : sidebarType === 3 ? (
							<small>Overview</small>
						) : sidebarType === 4 ? (
							<small>Staking Pools</small>
						) : sidebarType === 5 ? (
							<small>Lending</small>
						) : sidebarType === 6 ? (
							<small>Borrowing</small>
						) : sidebarType === 7 ? (
							<small>Withdrawal Request</small>
						) : null}
					</span>
				) : null}
			</Header>
		</>
	)
}

export default HeaderNavbar
