import React from 'react'
import { Layout, Space, Typography } from 'antd'
import HeaderNavbar from 'components/HeaderNavbar'
const { Text, Link } = Typography

const DepositView = () => {
	return (
		<Layout>
			<HeaderNavbar headerType={0} />
			<Space style={{ marginTop:'60px', paddingLeft:'16px', paddingRight:'16px'}}>
				<Text style={{ textAlign:'center'}}>You need to have { process.env.REACT_APP_LOGIN_TOKEN_BALANCE } RFX in your wallet to login</Text>
			</Space>
		</Layout>
	)
}

export default DepositView