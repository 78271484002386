import axios from 'axios'
// import localStore from 'helpers/localstore.js'

export const getRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.get(route, {
			params: {
				...payloads,
			},
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}
export const postRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.post(route, payloads)
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

// const config = {
// 	method: 'post',
// 	url: `${BACKEND_URL}/pools/newPool`,
// 	headers: { 'Content-Type': 'multipart/form-data' },
// 	data: formData,
// }

export const formPostRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.post(route, payloads, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const putRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.put(route, payloads)
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const deleteRequest = (route) => {
	return new Promise((resolve, reject)=>
		axios.delete(route)
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}