import React from 'react'
import { Row, Col, Spin } from 'antd'
import { useStakingPools } from './StakingProvider'
import { PoolType } from 'utils/types'
import PoolItem from './PoolItem'

const ExpiredOrCanceledPoolSection  = () => {

	const { pools, loading } = useStakingPools()
	const expiredPools = pools.filter((ele:PoolType)=>ele.live_data?.isInitialize && ele.live_data.isStopped)

	return (
		<div className="d-flex flex-column gap-3">
			<label className="available-main-title text-center">Expired and Cancelled Pools</label>
			<Row gutter={[8, 8]}>
				{loading ? (
					<div style={{ paddingTop: '60px' }}>
						<Spin />
					</div>
				) : expiredPools ? (
					expiredPools.map((item:PoolType) => {
						return (
							<Col key = {item.pool} xs={24} md={12} xxl={6}>
								<PoolItem
									item={item}
								/>
							</Col>
						)
					})
				) : null}
			</Row>
		</div>
	)
}

export default ExpiredOrCanceledPoolSection