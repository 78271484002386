import React, { useEffect } from 'react'
import useAuth from 'rftxStore/auth/auth.hook'
import { AccountProvider } from './AccountProvider'
import BalanceSection from './BalanceSection'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import TransactionHistorySection from './TransactionHistorySection'

const AccountView = () => {
	const { setHeaderType, setSidebarType } = useAuth()

	useEffect(() => {
		setHeaderType(0)
		setSidebarType(0)
	}, [])

	return (
		<AccountProvider>
			<div className="p-2 p-md-4 position-relative">
				{/* <Link to="/utilities" className="rp-info-btn border-btn ms-auto me-0">
				Reflections/Partition Information
				</Link> */}
				
				<Row className='w-100 mt-5'>
					<Col md={6} xs={1}></Col>
					<Col md={12} xs={22}>
						<BalanceSection />
					</Col>
					<Col md={6} xs={1}></Col>
				</Row>
				<TransactionHistorySection />
			</div>
		</AccountProvider>
	)
}

export default AccountView
