import { ethers, Signer } from 'ethers'

import { FetchSignerResult } from '@wagmi/core'
import { getContractsData } from 'utils/contracts'

export const getTokenBalance = async (token:string, signer?:FetchSignerResult<Signer>) => {

	const contractMap = getContractsData()

	if(signer){
		try {
			const signerAddress = await signer.getAddress()
			const tokenContract = new ethers.Contract(token, contractMap.rematicFinance.abi, signer)
			const balance = await tokenContract.balanceOf(signerAddress)
			return Number(ethers.utils.formatEther(balance))
		}catch(error){
			console.log(error)
		}
	}
	return 0
}

export const getReflectionsFromDefaultDivTracker = async (signer?:FetchSignerResult<Signer>) => {

	const contractMap = getContractsData()

	if(signer){
		try {
			const address = await signer.getAddress()
			const RFXDividendTrakcer = new ethers.Contract(contractMap.defaultTokenTracker.address, contractMap.defaultTokenTracker.abi, signer)
	
			const reflectionEarned = await RFXDividendTrakcer.withdrawnDividendOf(address)
				
			// const reflectionClaimable = await RFXDividendTrakcer.withdrawableDividendOf(address)
	
			return {
				earned: Number(ethers.utils.formatEther(reflectionEarned)),
				// claimable: ethers.utils.formatEther(reflectionClaimable)
			}
		} catch (err) {
			console.log(err)
		}
	}
	return {
		earned: 0,
	}
}


export const getPartitionEarned = async (signer?:FetchSignerResult<Signer>) => {
	if(signer){
		const address = await signer.getAddress()
		const contractMap = getContractsData()
		try {
			const RFX_PARTITION_CONTRACT = new ethers.Contract(
				contractMap.holdersPartition.abi,
				contractMap.holdersPartition.abi,
				signer
			)
			const partitionEarned = await RFX_PARTITION_CONTRACT
				.withdrawnDividends(address)
				
			return Number(partitionEarned) / Math.pow(10, 18)
		} catch (err) {
			console.log(err)
		}
	}
	return 0
}

export const getRFXStakedAmount = async (signer?:FetchSignerResult<Signer>) => {
	if(signer){
		const address = await signer.getAddress()
		const contractMap = getContractsData()
		try {
			const FSPFACTORY_CONTRACT = new ethers.Contract(
				contractMap.fspFactory.address,
				contractMap.fspFactory.abi,
				signer
			)

			const totalStakedAmount = await FSPFACTORY_CONTRACT
				.totalDepositAmount(address)
	
			return Number(ethers.utils.formatEther(totalStakedAmount))
		} catch (err) {
			console.log(err)
			return 0
		}
	}
	return 0
}


export const get401kReward = async (signer?:FetchSignerResult<Signer>) => {
	if(signer){
		const address = await signer.getAddress()
		const contractMap = getContractsData()
		try {
			const RFX401kContract = new ethers.Contract(contractMap.four01kprograme.address, contractMap.four01kprograme.abi, signer)
			const result = await RFX401kContract.four01kAmountMap(address)
			return Number(ethers.utils.formatEther(result))
		} catch (err) {
			console.log(err)
			return 0
		}
	}
	return 0
}

export const checkFSPPoolFactoryAdmin = async (signer?:FetchSignerResult<Signer>) => {
	if(signer){
		const address = await signer.getAddress()
		const contractMap = getContractsData()
		try {
			const fspFactory = new ethers.Contract(contractMap.fspFactory.address, contractMap.fspFactory.abi, signer)
			const result = await fspFactory.admins(address)
			return result
		} catch (err) {
			console.log(err)
		}
	}
	return false
}