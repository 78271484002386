
import React, { lazy } from 'react'

// project imports
import AccountView from 'views/AccountView'
import Staking401kView from 'views/Staking410kView'
import StakingView from 'views/StakingView'
import AdminOverView from 'views/AdminOverView'
import Withdrawal from 'components/Withdrawal'
import Utilities from 'components/Utilities'
import PlatformFees from 'components/PlatformFees'
import Disclaimers from 'components/Disclaimers'
import AdminStaking from 'views/AdminStaking'
import BorrowingView from 'views/LendingBorrowingView/BorrowingView'
import LendingView from 'views/LendingBorrowingView/LendingView'
import AdminWithdrawalRequest from 'views/AdminWithdrawalRequest'
import MainLayout from 'layout/MainLayout'


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
	{
		path: '',
		element: <MainLayout />,
		children: [
			{
				path: '',
				element: <AccountView />,
			},
			{
				path: '/account',
				element: <AccountView />,
			},
			{
				path: '/staking',
				element: <StakingView />,
			},
			{
				path: '/admin',
				element: <AdminOverView />,
			},
			{
				path: '/withdrawal',
				element: <Withdrawal />,
			},
			{
				path: '/utilities',
				element: <Utilities />,
			},
			{
				path: '/fees',
				element: <PlatformFees />,
			},
			{
				path: '/disclaimers',
				element: <Disclaimers />,
			},
			{
				path: '/stakingpool',
				element: <AdminStaking />,
			},
			{
				path: '/lending',
				element: <LendingView />,
			},
			{
				path: '/borrowing',
				element: <BorrowingView />,
			},
			{
				path: '/requests',
				element: <AdminWithdrawalRequest />,
			},
		]
	}
	
]

export default MainRoutes
