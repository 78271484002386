import React from 'react'
// ==============================|| LOADER ||============================== //
const Loader = () => (
	<div style={{
		position:'fixed',
		top:0,
		left:0,
		width:'100%',
		textAlign:'center',
		zIndex:1301,
	}}>
		<div>...loading</div>
	</div>
)

export default Loader
