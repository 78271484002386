import React, { useState } from 'react'
import { Row, Spin, Col } from 'antd'
import { PoolType } from 'utils/types'
import PoolItem from './PoolItem'
import { useStakingPools } from './StakingProvider'

const MyPoolSection  = () => {

	const { pools, loading } = useStakingPools()
	const mypools = pools.filter((pool)=>pool.live_data.userInfo?.stakedAmount.gt(0))
	return (
		<div>
			<label className="mystakes-main-title ms-2 fw-bold">My Pools</label>
			<Row gutter={[8, 8]}>
				{loading ? (
					<div style={{ paddingTop: '60px' }}>
						<Spin />
					</div>
				) : (
					mypools.map((item:PoolType) => {
						return (
							<Col key = {item.pool} xs={24} md={12} xxl={6}>
								<PoolItem item={item} />
							</Col>
						)
					})
				)}
			</Row>
		</div>
	)
}

export default MyPoolSection