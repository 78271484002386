import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './BackToMain.css'

const BackToMain = () => {
	let navigate = useNavigate()

	return (
		<div className="BackToButton">
			<button onClick={() => navigate(-1)}>Back</button>
		</div>
	)
}

export default BackToMain
