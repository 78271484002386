import React, { useState } from 'react'
import { Input, Row, Col, Spin } from 'antd'
import { useStakingPools } from './StakingProvider'
import { PoolType } from 'utils/types'
import PoolItem from './PoolItem'

const AvailablePoolSection  = () => {

	const { pools, loading } = useStakingPools()
	const [searchKey, setSearchKey] = useState('')
	let availablePools = pools.filter((ele:PoolType)=>ele.live_data.isInitialize && !ele.live_data.isStopped)
	if(searchKey != ''){
		availablePools = availablePools.filter(
			(ele:PoolType)=>(
				ele.project_name.toLowerCase().includes(searchKey) || 
                ele.stake_token.symbol.toLowerCase().includes(searchKey))
		)
	}

	return (
		<div>
			<div className="d-flex justify-content-between align-items-center w-100 pe-2">
				<label className="fs-3 fw-bold ms-2">Available Pools</label>
				<Input.Search
					style={{width:'300px'}}
					placeholder="Project name or symbol"
					onChange={(e) => setSearchKey(e.target.value)}
				/>
			</div>

			<Row gutter={[8, 8]}>
				{
					loading ? (
						<div style={{ paddingTop: '60px' }}>
							<Spin />
						</div>
					) : availablePools.map((item:PoolType) => {
						return (
							<Col key = {item.pool} xs={24} md={12} xxl={6}>
								<PoolItem
									item={item}
								/>
							</Col>
						)
					})
				}
			</Row>
		</div>
	)
}

export default AvailablePoolSection