import { BigNumber, ethers, Signer } from 'ethers'
import { NULL_ADDRESS } from '../utils'
import swal from 'sweetalert'
import { getContractsData } from 'utils/contracts'
import { FetchSignerResult } from '@wagmi/core'
import { PoolLiveDataType } from 'utils/types'

export const deployPool = async (
	stakedAddress:string,
	reflectionAddress:string,
	tokenSupply:number,
	apy:number,
	lockTimeType:string,
	limitAmount:number,
	isPartition:boolean,
	isPrivate:boolean,
	whitelist_addresses:string[],
	signer?:FetchSignerResult<Signer>
) => {

	if(!signer) throw new Error('Please connect wallet')

	const contractMap = getContractsData()

	
	const signerAddress = await signer.getAddress()

	// eslint-disable-next-line no-debugger
	// debugger

	const tokenABI = [
		'function approve(address _spender, uint256 _value) public returns (bool success)',
		'function totalSupply() public view returns(uint256)',
		'function allowance(address owner, address spender) public view returns(uint256)',
		'function balanceOf(address account) public view returns(uint256)',
	]

	const tokenContract = new ethers.Contract(stakedAddress, tokenABI, signer)

	const rewardSupply = BigNumber.from(String(tokenSupply)).mul(
		Math.pow(10, 18).toString()
	)

	const lmtAmount = BigNumber.from(String(limitAmount)).mul(
		Math.pow(10, 18).toString()
	)

	const balance = await tokenContract.balanceOf(signerAddress)

	if (Number(balance) < Number(rewardSupply)) {
		throw new Error('token balance is not enough.')
	}

	const allowance = await tokenContract.allowance(
		signerAddress,
		contractMap.fspFactory.address
	)
	const approveAmount = rewardSupply.sub(allowance)

	if (approveAmount.gt(0)) {
		const approveTx = await tokenContract.approve(
			contractMap.fspFactory.address,
			rewardSupply
		)
		await approveTx.wait()
	}

	const contract = new ethers.Contract(
		contractMap.fspFactory.address,
		contractMap.fspFactory.abi,
		signer
	)


	const creationFee = await contract.getCreationFee(lockTimeType)


	const userBalance = await signer.getBalance()

	if (Number(userBalance) < Number(creationFee)) {
		throw new Error('Insufficient BNB to create Pool.')
	}

	if (reflectionAddress === '' || reflectionAddress === undefined) {
		reflectionAddress = NULL_ADDRESS
	}

	// let d = await provider.getGasPrice()

	// const gasPrice = await contract.estimateGas.deployPool(
	// 	stakedAddress,
	// 	reflectionAddress,
	// 	rewardSupply,
	// 	apy,
	// 	lockTimeType,
	// 	limitAmount,
	// 	isPartition,
	// 	{
	// 		value: creationFee,
	// 	}
	// )

	// console.log('gasPrice ===>', gasPrice)

	console.log('deploy pool -----')


	const tx = await contract.deployPool(
		stakedAddress,
		reflectionAddress,
		rewardSupply,
		apy,
		lockTimeType,
		lmtAmount,
		isPartition,
		isPrivate,
		{
			value: creationFee,
			// gasPrice: parseInt(1.2 * d),
			// gasLimit: gasPrice,
			// maxFeePerGas: null,
		}
	)
	const receipt = await tx.wait()
	const poolAddress = receipt.events[0].address
	if(isPrivate){
		const poolContract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		await poolContract.whilteListBulkAccounts(whitelist_addresses, true)

	}
	

	return poolAddress
}

export const cancelPool = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {

	if (!signer) throw new Error('Please connect Wallet')

	const contractMap = getContractsData()
	const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
	const tx = await contract.stopReward()
	await tx.wait()
	swal('Good job!', 'Pool canceled', 'success')
}

export const depositTokens = async (
	poolAddress:string,
	stakedAddress:string,
	depositAmount:number,
	limitAmount:number,
	isCanceled?:boolean,
	signer?:FetchSignerResult<Signer>
) => {
	try {
		if (!signer) throw new Error('Please connect Wallet')

		if (depositAmount <= 0) {
			throw new Error('Deposit Amount must be greater than zero')
		}
		if (isCanceled) {
			throw new Error('Pool is ended')
		}

		if (limitAmount !== 0 && depositAmount > limitAmount) {
			throw new Error(
				'Your deposit amount exceed limit amount. Limit amount is ' +
          limitAmount +
          '.'
			)
		}

		const signerAddress = await signer.getAddress()

		const tokenABI = [
			'function approve(address _spender, uint256 _value) public returns (bool success)',
			'function totalSupply() public view returns(uint256)',
			'function allowance(address owner, address spender) public view returns(uint256)',
			'function balanceOf(address account) public view returns(uint256)',
		]

		const tokenContract = new ethers.Contract(stakedAddress, tokenABI, signer)

		const depositAmt = BigNumber.from(String(depositAmount)).mul(
			Math.pow(10, 18).toString()
		)

		const balance = await tokenContract.balanceOf(signerAddress)

		if (Number(balance) < Number(depositAmt)) {
			throw new Error(
				'Your Staking Token balance is less than the depoist amount'
			)
		}

		if (depositAmt.gt(0)) {
			const approveTx = await tokenContract.approve(poolAddress, depositAmt)
			await approveTx.wait()
		}
		const contractMap = getContractsData()
		const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		const isReflection = await contract.isReflectionToken()
		const depoistFee = await contract.getDepositFee(isReflection)
		const userBalance = await signer.getBalance()

		if (Number(userBalance) < Number(depoistFee)) {
			throw new Error('Insufficient BNB to deposit.')
		}

		// const d = await signer.getGasPrice()

		// const gasPrice = await contract.estimateGas.deposit(depositAmt, {
		// 	value: depoistFee,
		// })

		const tx = await contract.deposit(depositAmt, {
			value: depoistFee,
			// gasPrice: parseInt(1.2 * d),
			// gasLimit: gasPrice,
			// maxFeePerGas: null,
		})
		await tx.wait()
		swal('Deposit', 'Deposit successfully', 'success')
		return true
	} catch (err:any) {
		if (String(err.message).includes('pool is ended')) {
			swal('Sorry!', 'Pool is ended', 'error')
		} else if (
			String(err.message).includes(
				'deposit amount exceed the max stake token amount'
			)
		) {
			swal(
				'Sorry!',
				'Your deposit token amount exceed maximum token amounts of this pool',
				'error'
			)
		} else if (
			String(err.message).includes('Pool owner didn\'t send the reward tokens')
		) {
			swal('Sorry!', 'The pool owner did not send any reward tokens.', 'error')
		} else if (String(err.message).includes('Deposit limit exceeded')) {
			swal('Sorry!', 'Deposit limit exceeded', 'error')
		} else if (
			String(err.message).includes(
				'Your Staking Token balance is less than the depoist amount'
			)
		) {
			swal(
				'Sorry!',
				'Your Staking Token balance is less than the depoist amount.',
				'error'
			)
		} else if (String(err.message).includes('Insufficient BNB to deposit.')) {
			swal('Sorry!', 'Insufficient BNB to deposit.', 'error')
		} else if (String(err.message).includes('exceed remain capacity')) {
			swal('Sorry!', 'You are attempting to stake more tokens than the available capacity. Please look for another pool, if another pool is not available, please contact the project team of the token you are trying to stake. Your transaction has been Reverted.', 'error')
		} else if ( String(err.message).includes('Caller is not whitelisted') ) {
			swal('Sorry!', 'Sorry. You are not Whitelisted for this private pool. Please contact the team of the Token you are trying to stake if you feel this is a mistake.', 'error')
		} else {
			console.log(err.message)
			swal('Sorry!', err.message, 'error')
		}
	}
	return false
}

export const withdraw = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {
	const contractMap = getContractsData()
	if(signer){
		try {
			const signerAddress = await signer.getAddress()
			const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
			const poolStatus = await contract.getPoolStatus()
			const isReflection = await contract.isReflectionToken()
			let claimFee
			if (poolStatus) {
				claimFee = await contract.getCanceledWithdrawFee(isReflection)
			} else {
				claimFee = await contract.getEarlyWithdrawFee(isReflection)
			}
	
			const userBalance = await signer.getBalance()
	
			if (Number(userBalance) < Number(claimFee)) {
				throw new Error('Insufficient BNB to withdraw.')
			}
			const tx = await contract.withdraw({ value: claimFee })
			await tx.wait()
			swal(
				'Withdraw',
				'You have successfully withdrawn your deposit tokens.',
				'success'
			)
		} catch (err:any) {
			if (String(err.message).includes('withdrawFee is not enough')) {
				swal('Sorry!', 'Withdraw fee is not enough', 'error')
			} else if (
				String(err.message).includes(
					'No tokens have been deposited into this pool'
				)
			) {
				swal('Sorry!', 'No tokens deposited in this pool!', 'error')
			} else if (String(err.message).includes('Insufficient BNB to withdraw.')) {
				swal('Sorry!', 'Insufficient BNB to withdraw.', 'error')
			} else {
				console.log(err.message)
				swal('Sorry!', 'Transaction Reverted', 'error')
			}
			console.log(err)
		}
	}

}

export const rewardClaim = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {

	const contractMap = getContractsData()

	try {
		if(signer){
			const signerAddress = await signer?.getAddress()
			const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
			const isReflection = await contract.isReflectionToken()
			const claimFee = await contract.getRewardClaimFee(isReflection)
	
			const userBalance = await signer.getBalance()
	
			if (Number(userBalance) < Number(claimFee)) {
				throw new Error('Insufficient BNB to claim your reward.')
			}
			const tx = await contract.claimReward({ value: claimFee })
			await tx.wait()
			swal('Claim', 'You have successfully claimed your reward.', 'success')
		}

	} catch (err:any) {
		if (String(err.message).includes('claim fee is not enough')) {
			swal('Sorry!', 'Claim fee is not enough', 'error')
		} else if (
			String(err.message).includes('There are no claimable tokens in this pool')
		) {
			swal('Sorry!', 'There are no claimable tokens in this pool.', 'error')
		} else if (
			String(err.message).includes('Insufficient BNB to claim your reward.')
		) {
			swal('Sorry!', 'Insufficient BNB to claim your reward.', 'error')
		} else {
			console.log(err.message)
			swal('Sorry!', 'Transaction Reverted', 'error')
		}
		console.log(err)
	}
}

export const reflectionClaim = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {

	const contractMap = getContractsData()

	try {

		if(signer){
			const signerAddress = await signer.getAddress()
			const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
			const claimFee = await contract.getReflectionFee()
			const userBalance = await signer.getBalance()
	
			if (Number(userBalance) < Number(claimFee)) {
				throw new Error('Insufficient BNB to claim your reflection reward.')
			}
			const tx = await contract.claimReflections({ value: claimFee })
			await tx.wait()
			swal(
				'Claim',
				'You have successfully claimed your reflection tokens.',
				'success'
			)
		}

	} catch (err:any) {
		if (String(err.message).includes('no reflection claimable tokens')) {
			swal(
				'Sorry!',
				'There are currently no reflection claimable tokens.',
				'error'
			)
		} else if (String(err.message).includes('reflection fee is not enough')) {
			swal('Sorry!', 'Reflection fee is not enough.', 'error')
		} else if (
			String(err.message).includes(
				'Insufficient BNB to claim your reflection reward.'
			)
		) {
			swal(
				'Sorry!',
				'Insufficient BNB to claim your reflection reward.',
				'error'
			)
		} else {
			console.log(err.message)
			swal('Sorry!', 'Transaction Reverted', 'error')
		}
	}
}

// export const emergencyWithdraw = async (poolAddress) => {
//   try {
//     if (!window.ethereum) {
//       throw new Error("No wallet on the browser.");
//     }

//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();
//     const signerAddress = await signer.getAddress();
//     const contract = new ethers.Contract(poolAddress, poolABI.abi, signer);
//     const emergencyWithdrawFee = await contract.getEmergencyWithdrawFee();

//     const userBalance = await provider.getBalance(signerAddress);

//     if (Number(userBalance) < Number(emergencyWithdrawFee)) {
//       throw new Error("Insufficient BNB to emergencyWithdraw.");
//     }

//     const tx = await contract.emergencyWithdraw({
//       value: emergencyWithdrawFee,
//     });
//     await tx.wait();
//     swal(
//       "Emergency Withdraw!",
//       "You got Emergency Withdrawn. No rewards from the pool!",
//       "warning"
//     );
//   } catch (err) {
//     console.log(err.message);
//     swal("Sorry!", "Transaction Reverted", "error");
//   }
// };

export const emergencyWithdrawByOwner = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {


	if (!signer) throw new Error('Please connect Wallet')

	const contractMap = getContractsData()

	try {
		const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		const tx = await contract.emergencyWithdrawByPoolOwner()
		await tx.wait()
		swal(
			'Claim',
			'You have successfully claimed the rest staked and reflection tokens from the pool.',
			'success'
		)
	} catch (err:any) {
		if (String(err.message).includes('pool is not ended yet')) {
			swal('Sorry!', 'Pool is not canceled yet!', 'error')
		} else if (
			String(err.message).includes('deposit amount exceed the max stake token')
		) {
			swal('Sorry!', 'You deposit amount exceed Max token amount', 'error')
		} else if (
			String(err.message).includes(
				'already withdrawn the rest staked and reflection token'
			)
		) {
			swal(
				'Sorry!',
				'The remaining staking and reflection tokens have already been withdrawn from the pool.',
				'error'
			)
		} else {
			console.log(err.message)
			swal('Sorry!', 'Transaction Reverted', 'error')
		}
	}
}

export const transferToPool = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {
	
	if (!signer) throw new Error('Please connect Wallet')

	const contractMap = getContractsData()

	try {
		const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		const rewardSupply = await contract.rewardSupply()
		const tokenABI = [
			'function approve(address _spender, uint256 _value) public returns (bool success)',
			'function totalSupply() public view returns(uint256)',
			'function allowance(address owner, address spender) public view returns(uint256)',
			'function balanceOf(address account) public view returns(uint256)',
		]
		const owner = await signer.getAddress()
		const stakedToken = await contract.stakedToken()
		const tokenContract = new ethers.Contract(stakedToken, tokenABI, signer)
		if (rewardSupply.gt(0)) {
			const approveTx = await tokenContract.approve(poolAddress, rewardSupply)
			await approveTx.wait()
		}
		const tx = await contract.rewardTokenTransfer()
		await tx.wait()

		swal(
			'Transfer',
			'The reward token has been successfully transferred to the pool.',
			'success'
		)
	} catch (err:any) {
		if (String(err.message).includes('pool is not ended yet')) {
			swal('Sorry!', 'Pool is not canceled yet!', 'error')
		} else if (
			String(err.message).includes('deposit amount exceed the max stake token')
		) {
			swal('Sorry!', 'You deposit amount exceed Max token amount', 'error')
		} else {
			console.log(err)
			swal('Sorry!', 'Transaction Reverted', 'error')
		}
	}
}

export const forceStop = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {

	if (!signer) throw new Error('Please connect Wallet')

	const contractMap = getContractsData()
	try {
		const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		const tx = await contract.emergencyWithdrawByPlatformOwner()
		await tx.wait()
		swal('Force Stop', '', 'warning')
	} catch (err:any) {
		if (String(err.message).includes('pool is not ended yet')) {
			swal('Sorry!', 'Pool is not canceled yet!', 'error')
		} else if (
			String(err.message).includes('deposit amount exceed the max stake token')
		) {
			swal('Sorry!', 'You deposit amount exceed Max token amount', 'error')
		} else if (String(err.message).includes('You are not Platform Owner')) {
			swal('Sorry!', 'You are not Platform Owner!', 'error')
		} else {
			console.log(err.message)
			swal('Sorry!', 'Transaction Reverted', 'error')
		}
	}
}

export const compound = async (poolAddress:string, signer?:FetchSignerResult<Signer>) => {

	if (!signer) throw new Error('Please connect Wallet')

	const contractMap = getContractsData()
	try {
		const contract = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		const tx = await contract.compound()
		await tx.wait()
		swal(
			'Compound',
			'Success!',
			'success'
		)
		return true
	} catch (err:any) {
		console.log(err.message)
		swal('Sorry!', 'Transaction Reverted', 'error')
	}
	return false
}

export const getPoolInfo = async (poolAddress:string, ogliveData:PoolLiveDataType, signer?:FetchSignerResult<Signer>) : Promise<PoolLiveDataType> => {
	// 
	if (!signer) throw new Error('Please connect Wallet')
	const contractMap = getContractsData()
	try {
		const account = await signer.getAddress()
		const pool = new ethers.Contract(poolAddress, contractMap.fspPool.abi, signer)
		if(ogliveData.isInitialize && !ogliveData.isStopped){

			// fetch pool live data
			const userInfo = await pool.userInfo(account)
			const pendingAmount = await pool.pendingReward(account)
			const reflectionReward = await pool.reflectionClaimable(account)
			const tts = await pool.getTotalStaked()
			let ttx = 0
			try {
				ttx = await pool.getTotalCompound()
			}catch(error){
				console.log('error -- pool.getTotalCompound')
			}

			const remainingCapacity = await pool.getRemainCapacity()
			const lifeTimeVal = await pool.getPoolLifeTime()

			let lifeTime = lifeTimeVal.toNumber()
			const days = Math.floor(lifeTime / 86400)
			lifeTime -= days * 86400
			const hours = Math.floor(lifeTime / 3600) % 24
			lifeTime -= hours * 3600
			const minutes = Math.floor(lifeTime / 60) % 60
			lifeTime -= minutes * 60


			return {
				...ogliveData,
				pendingAmount,
				remainingCapacity:remainingCapacity.toString(),
				totalStakedAmount:tts.add(ttx),
				reflectionReward,
				lifeTime:{
					days,
					hours,
					minutes
				},
				userInfo,
			}
		}
	}catch(error:any){
		console.log(error)
	}
	if(poolAddress == '0xdde8c8336bCAec84C509d7cC9DF29eA8146a73F6') console.log('ogliveData ===>', ogliveData)
	return ogliveData
}