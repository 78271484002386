import React, { createContext, useState, useEffect, useContext } from 'react'
import * as PoolAPI from 'api/api-pools'
import { PoolType } from 'utils/types'
import { getPoolInfo } from 'web3Client/pool'
import { useSigner } from 'wagmi'

interface ProviderProps {
    loading:boolean,
    pools: PoolType[],
    fetchPools:()=>void,
}

export const StakingContext = createContext<ProviderProps>({
	loading:true,
	pools: [],
	fetchPools: function (): void {
		throw new Error('Function not implemented.')
	}
})

interface Props {
    children:React.ReactNode
}
export const StakingProvider = ({ children }:Props) => {

	const [pools, setPools] = useState<PoolType[]>([])
	const [loading, setLoading] = useState(true)
	const { data: signer } = useSigner()

	useEffect(()=>{
		if(signer) fetchPools()
	}, [signer])

	const fetchPools = async () => {
		setLoading(true)
		const { data } = await PoolAPI.getAllPools()

		for(let i = 0; i < data.length; i++){
			const pool:PoolType = data[i]
			pool.live_data = await getPoolInfo(pool.pool, pool.live_data, signer)
		}
		setPools(data)
		// setAvailableItems(data.filter((ele:PoolType)=>ele.live_data.isInitialize && !ele.live_data.isStopped))
		// setCanceledItems(data.filter((ele:PoolType)=>ele.live_data?.isInitialize && ele.live_data.isStopped))
		// setAllPoolItms(data)
		setLoading(false)
	}


	return (
		<StakingContext.Provider 
			value={{
				loading,
				pools,
				fetchPools
			}}
		>
			{children}
		</StakingContext.Provider>
	)
}

export const useStakingPools = () => useContext(StakingContext)