import React from 'react'
import { Checkbox, Divider, Form, Input, Select, Slider, Drawer } from 'antd'
import LendingBorrowingContext from '../context'

const { Option } = Select

export default ()=> {

	const [filterChecked, setFilterChecked] = React.useState(false)
	const {borrowableLoans, setBorrowableLoans, filter, setFilter} = React.useContext(LendingBorrowingContext)
	const [form] = Form.useForm()

	const onChangeFilterCheck = (e) => {
		setFilterChecked(e.target.checked)
		if(!e.target.checked){
			setFilter({})
			form.resetFields()
		}
	}

	const onChangeFilterForm = (values) => {
		setFilter({...filter, ...values})
	}

	return (
		<div style={{fontSize:'22px'}}>
			<div>
				<Checkbox checked = {filterChecked} onChange={onChangeFilterCheck}>Filter</Checkbox>
			</div>

			{/* <Drawer>

            </Drawer> */}

			<div style={{ width:'100%', height: filterChecked?'400px':0, transition: 'height 0.15s ease-out', overflow: 'hidden'}} className = "px-4" >
				<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onValuesChange = {onChangeFilterForm}>
					<Divider />
					<Form.Item
						label="Payment Period"
						name="paymentPeriod"
					>
						<Select style={{ width: 120 }}>
							<Option value={7}>Weekly</Option>
							<Option value={14}>BiWeekly</Option>
							<Option value={30}>Monthly</Option>
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item
						label="Duration"
						name="duration"
					>
						<Select style={{ width: 120 }}>
							<Option value={30}>30 Days</Option>
							<Option value={90}>90 Days</Option>
							<Option value={180}>180 Days</Option>
							<Option value={365}>365 Days</Option>
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item label = "Available Funds" name = "availableAmount">
						<Input type='number' min = {0} step={10}/>
					</Form.Item>
					<Divider />
					<Form.Item label = "Min Amount" name = "min_amount">
						<Input type='number' min = {0} step={10}/>
					</Form.Item>
					<Form.Item label = "Max Amount" name = "max_amount">
						<Input type='number' step={10}/>
					</Form.Item>
					<Divider />
					<Form.Item label ="ARP" name ="aPRInerestRate">
						<Slider range marks = {{0:'0%', 100:'100%'}} defaultValue={[0, 100]} trackStyle = {{ backgroundColor:'var(--color-bg)' }} handleStyle = {{ borderColor:'var(--color-bg)' }}/>
					</Form.Item>
					<Divider />
				</Form>
			</div>
		</div>
	)
}