import React, { useEffect } from 'react'
import useAuth from 'rftxStore/auth/auth.hook'

import { StakingProvider } from './StakingProvider'
import MyPoolSection from './MyPoolSection'
import AvailablePoolSection from './AvailablePoolSection'
import ExpiredOrCanceledPoolSection from './ExpiredOrCanceledPoolSection'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const StakingView = () => {
	const { setSidebarType, setHeaderType } = useAuth()
	useEffect(() => {
		setSidebarType(2)
		setHeaderType(0)
	}, [])

	return (
		<StakingProvider>
			<div className="p-2 p-md-4 position-relative">
				<Link to="/utilities" className="rp-info-btn border-btn ms-auto me-0">Staking Information</Link>
				<div className="d-flex flex-column gap-5">
					<MyPoolSection />
					<div className="staking-bar"></div>
					<AvailablePoolSection />
					<div className="staking-bar"></div>
					<ExpiredOrCanceledPoolSection />
				</div>
			</div>
		</StakingProvider>
	)
}

export default StakingView
