
import { getRequest, formPostRequest } from './api-requests'
import config from './config'

// eslint-disable-next-line no-undef
const REACT_APP_CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 97

export const getContracts = () => getRequest(`${config.host}/api/v1/rftx/contracts/${REACT_APP_CHAIN_ID}`)
export const getSupportTokens = () => getRequest(`${config.host}/api/v1/rftx/tokens/${REACT_APP_CHAIN_ID}`)

export const createPool = (payloads) => formPostRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/`, payloads)
export const getPools = (pool) => getRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/${pool}`)
export const getMyPools = (address) => getRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/owner/${address}`)