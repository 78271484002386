import React, { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import useAuth from 'rftxStore/auth/auth.hook'
import { useAlertError } from '../../hooks/useAlertError'
import WithdrawalRequest from '../../components/WithdrawalRequest'
import apiConfig from 'api/config'

// eslint-disable-next-line no-undef
const REACT_APP_OWNER = process.env.REACT_APP_OWNER || ''
const BACKEND_URL = `${apiConfig.host}/api/v1`

const AdminWithdrawalRequest = () => {
	const { setSidebarType, setHeaderType } = useAuth()
	const { address, isConnected } = useAccount()
	const [requestData, setRequestData] = useState()
	const [loadingRequest, setLoadingRequest] = useState(false)
	const navigate = useNavigate()
	const alertError = useAlertError()

	useEffect(() => {
		setHeaderType(0)
		setSidebarType(7)
	}, [])

	useEffect(() => {
		if (isConnected) {
			setSidebarType(7)
			loadAdminData()
		} else if (isConnected == false) {
			navigate('/account')
		}
	}, [address, isConnected])

	const loadAdminData = async () => {
		if (address) {
			await checkAdmin()
			await loadWithdrawalRuquest()
		} else {
			navigate('/account', { replace: false })
		}
	}

	const checkAdmin = async () => {
		if (address) {
			if (address.toLowerCase() !== REACT_APP_OWNER.toLowerCase()) {
				alertError(new Error('You are not owner'))
				navigate('/account', { replace: false })
			}
		} else {
			alertError(new Error('Please connect Metamask'))
		}
	}

	const loadWithdrawalRuquest = async () => {
		try {
			setLoadingRequest(true)
			const { data } = await axios.get(`${BACKEND_URL}/withdrawal/getRequests`)
			const res_data = data.result.map((requestItem:any, index:number) => {
				return {
					key: index,
					email: requestItem.email,
					walletAddress: requestItem.address,
					reason: requestItem.reason,
					requestDate: requestItem.createdAt,
					delete: requestItem._id,
				}
			})
			setRequestData(res_data)
			setLoadingRequest(false)
		} catch (err) {
			console.log(err)
		}
	}

	const handleRequestDelete = async (key:any) => {
		try {
			await axios.post(`${BACKEND_URL}/withdrawal/deleteRequest`, {
				requestId: key,
			})
			loadWithdrawalRuquest()
		} catch (err:any) {
			console.log(err.message)
		}
	}

	return (
		<WithdrawalRequest
			requestData={requestData}
			handleRequestDelete={handleRequestDelete}
			loadingRequest={loadingRequest}
		/>
	)
}

export default AdminWithdrawalRequest
