import axios from 'axios'
import swal from 'sweetalert'
import { BigNumber, ethers } from 'ethers'
import apiConfig from 'api/config'

// eslint-disable-next-line no-undef
const BACKEND_URL = `${apiConfig.host}/api/v1`

export const convertTokenPrice = (value) => {
	const digits = Math.floor(Math.log10(value)) + 1
	return value.toFixed(2 - Math.min(digits, 0))
}

export const validateAddress = (address) => /^0x[a-f0-9A-F]{40}$/.test(address)
export const AddressValidator = (_, address) => /^0x[a-f0-9A-F]{40}$/.test(address)?Promise.resolve():Promise.reject('Not valid')
export const PositiveNumberValidator = (_, val) => val>0?Promise.resolve():Promise.reject('Not valid')

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const checkCreatePoolData = (
	stakedAddress,
	reflectionAddress,
	tokenSupply,
	apy,
	lockTimeType,
	limitAmount,
	stakedSymbol,
	reflectSymbol,
	logoImg,
	projectName,
	websiteUrl,
	twitterUrl,
	youtubeUrl,
	discordUrl,
	telegramUrl,
	isReflection,
	isPartition,
	redditUrl
) => {
	if (!validateAddress(stakedAddress)) {
		swal('Sorry!', 'Stake Token Address is invalid type', 'error')
		return false
	}

	if (tokenSupply <= 0) {
		swal('Sorry!', 'Reward Supply must be greater than zero.', 'error')
		return false
	}

	if (apy <= 0) {
		swal('Sorry!', 'APY must be greater than zero.', 'error')
		return false
	}

	if (stakedSymbol === '') {
		swal('Sorry!', 'Please check your Stake token Symbol.', 'error')
		return false
	}

	if (projectName === '') {
		swal('Sorry!', 'Please check your Project Name.', 'error')
		return false
	}

	if (isReflection && !isPartition && !validateAddress(reflectionAddress)) {
		swal('Sorry!', 'Reflection Token Address is invalid type', 'error')
		return false
	}

	if (!logoImg) {
		swal('Sorry!', 'Please upload the logo img for Pool.', 'error')
		return false
	}

	return true
}

export const fetchAllStakingPools = async (address) => {
	try {
		const { data } = await axios.get(
			`${BACKEND_URL}/pools/getAllCreatedPools`,
			{
				params: {
					address: address,
				},
			}
		)

		return data.result
	} catch (err) {
		console.log(err)
	}
}

export const filterStakingPoolItems = (filterValues, allPoolItems) => {
	if (filterValues.includes(0) && filterValues.includes(1)) {
		return allPoolItems.filter(
			(item) => item.depositTokens > 0 && !item.isCanceled
		)
	}

	if (filterValues.includes(0)) {
		return allPoolItems.filter((item) => item.depositTokens > 0)
	}

	if (filterValues.includes(1)) {
		return allPoolItems.filter((item) => !item.isCanceled)
	}

	return allPoolItems
}

export const filterMyStakesItems = (allPoolItems) => {
	if (allPoolItems) {
		return allPoolItems.filter((item) => item.depositTokens > 0)
	}

	return []
}

export const filterAvailableItems = (allPoolItems, projectName) => {
	if (allPoolItems) {
		return allPoolItems.filter((item) =>
			projectName === ''
				? Number(item.totalStakedTokens) <= Number(item.maximumStakeable) &&
!item.isCanceled
				: Number(item.totalStakedTokens) <= Number(item.maximumStakeable) &&
!item.isCanceled &&
(String(item.projectName)
	.toLowerCase()
	.includes(String(projectName).toLowerCase()) ||
String(item.stakedSymbol)
	.toLowerCase()
	.includes(String(projectName).toLowerCase()))
		)
	}
	return []
}

export const filterCanceledItems = (allPoolItems) => {
	if (allPoolItems) {
		return allPoolItems.filter(
			(item) =>
				Boolean(item.isCanceled) ||
(item.lifeTime.days <= 0 &&
item.lifeTime.hours <= 0 &&
item.lifeTime.minutes <= 0)
		)
	}
	return []
}

export const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
})

export const filterLoans = (source, filterOptions) => {
	if (!filterOptions) return source
	return source.filter(function (loanEl) {
		let result = true
		Object.keys(filterOptions).map((key) => {
			if (key === 'paymentPeriod') {
				if (loanEl.paymentPeriod != filterOptions[key]) {
					result = false
				}
			}
			if (key === 'duration') {
				if (loanEl.duration != filterOptions[key]) {
					result = false
				}
			}
			if (key === 'min_amount') {
				if (
					result &&
parseFloat(ethers.utils.formatUnits(loanEl.tokenAmount, 'ether')) <
parseFloat(filterOptions[key])
				) {
					result = false
				}
			}

			if (key === 'max_amount') {
				if (
					result &&
parseFloat(ethers.utils.formatUnits(loanEl.tokenAmount, 'ether')) >
parseFloat(filterOptions[key])
				) {
					result = false
				}
			}

			if (key === 'availableAmount') {
				if (
					result &&
parseFloat(
	ethers.utils.formatUnits(loanEl.availableAmount, 'ether')
) > parseFloat(filterOptions[key])
				) {
					result = false
				}
			}

			if (key === 'aPRInerestRate') {
				if (
					(result && loanEl.aPRInerestRate < filterOptions[key][0]) ||
loanEl.aPRInerestRate > filterOptions[key][1]
				) {
					result = false
				}
			}
		})
		return result
	})
}


export const roundStr = (decimalStr, numDecimalPlaces) => {
	const roundedNum = parseFloat(decimalStr).toFixed(numDecimalPlaces)
	const roundedStr = roundedNum.toString()
	return roundedStr
}

export const roundBigNum = (bigNumber) => {
	if(bigNumber) {
		const val = ethers.utils.formatUnits(BigNumber.from(bigNumber))
		return roundStr(val, 2)
	}
	return '0'
}


// formatter and parser input number
export const formatterNumber = (val) => {
	if (!val) return '0'
	return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
  
export const parserNumber = (val) => {
	if (!val) return '0'
	return val.replace(/\$\s?|(,*)/g, '')
}
  