import React, { useEffect, useState } from 'react'
import './Staking.css'
import {
	FaTwitter,
	FaTelegram,
	FaDiscord,
	FaYoutube,
	FaReddit,
	FaGlobe,
} from 'react-icons/fa'
import {
	compound,
	depositTokens,
	getPoolInfo,
	reflectionClaim,
	rewardClaim,
	withdraw,
} from '../../web3Client/pool'
import NumberFormat from 'react-number-format'
import { useSigner } from 'wagmi'
import { PoolType } from 'utils/types'
import { Button, Typography, Row, Col, notification } from 'antd'
import { roundBigNum } from 'utils'
import { updatePoolLiveData } from 'api/api-pools'
import moment from 'moment'
import { CopyOutlined } from '@ant-design/icons'
import StakeForm from './StakeForm'
import { useStakingPools } from './StakingProvider'

const { Text, Title } = Typography

interface Props {
	item:PoolType;
}

const PoolItem = ({ item }:Props) => {

	const { fetchPools } = useStakingPools()


	const { data: signer } = useSigner()
	const [poolData, setPoolData] = useState(item)

	useEffect(() => {
		setPoolData(item)
	}, [item])

	const handleRewardClaim = async () => {
		try {
			await rewardClaim(poolData.pool, signer)
			await updatePoolLiveData(poolData.pool)
			fetchPools()
		} catch (err) {
			console.log(err)
		}
	}

	const handleReflectionClaim = async () => {
		try {
			await reflectionClaim(poolData.pool, signer)
			await updatePoolLiveData(poolData.pool)
			fetchPools()
		} catch (err) {
			console.log(err)
		}
	}

	const handleWithdraw = async () => {
		withdraw(poolData.pool, signer)
	}
	
	const handleCompound = async () => {
		const result = await compound(poolData.pool, signer)
		if(result)	{
			await updatePoolLiveData(poolData.pool)
			fetchPools()
		}
	}

	const onFinishDeposit = async (depositAmount:any) => {
		console.log('depositAmount ==>', depositAmount)
		const result = await depositTokens(
			poolData.pool,
			poolData.stake_token.address,
			depositAmount,
			poolData.max_deposit,
			poolData.live_data.isStopped,
			signer
		)
		if(result){
			await updatePoolLiveData(poolData.pool)
			fetchPools()
		}
	}

	return (
		<div className="row border border-success border-3 custom-pill-10 p-3 me-2 ms-2" style={{ backgroundColor: '#cdf0ea' }}>
			<Row>
				<Col span={12}>
					<div className='d-flex'>
						<img src={poolData.logoImg} width={80} height={80} />
						<div className='d-flex flex-column justify-content-center ms-2'>
							<label className='fw-bold'>{poolData.project_name}</label>
							<label className='fw-bold'>{poolData.stake_token.symbol}</label>
						</div>
					</div>
				</Col>
				<Col span={12}>
					<div className={`custom-tv-shape-${poolData.is_private?'private':'public'} fw-bold fs-6 ms-auto me-0`}>
						{poolData.is_private?'Private':'Public'} Pool
					</div>
					<div className='me-0 ms-auto' style={{width:'max-content'}}>
						<span className='fw-bold'>APY</span><label className='fw-bold fs-2'>{poolData.apy}%</label>
					</div>
				</Col>
			</Row>

			<Title level={3}>Pool <Text>DETAILS</Text>
				{/* {contextHolder} */}
				<Button shape='circle' icon={<CopyOutlined />} style={{ background:'transparent', marginLeft:'8px'}} onClick={()=>{
					navigator.clipboard.writeText(poolData.pool).then(function() {
						notification.open({
							message: 'Pool address is copied!',
							// description: 'Pool address is copied!',
							placement:'topRight',
							// icon: <S />,
						})
						console.log('Async: Copying to clipboard was successful!')
					}, function(err) {
						console.error('Async: Could not copy text: ', err)
					})
				}}/>
			</Title>

			<div className='ps-3 pe-3 pb-3'>
				<div className='d-flex justify-content-between'>
					<Text >Create Date</Text>
					<Text >{moment(poolData.createdAt).format('L hh:mm a')}</Text>
				</div>

				<div className='d-flex justify-content-between'>
					<Text >Expires in</Text>
					<Text >{ poolData.live_data.lifeTime.days} Days {poolData.live_data.lifeTime.hours} Hours { poolData.live_data.lifeTime.minutes} Min</Text>
				</div>

				<div className='d-flex justify-content-between'>
					<Text >Total Staked</Text>
					<NumberFormat value={roundBigNum(poolData.live_data.totalStakedAmount)} displayType={'text'} thousandSeparator={true} />
				</div>

				<div className='d-flex justify-content-between'>
					<Text >Individual Max</Text>
					<NumberFormat value={Number(poolData.max_deposit).toFixed(2)} displayType={'text'} thousandSeparator={true} />
				</div>

				<div className='d-flex justify-content-between'>
					<Text >Avail.Capacity</Text>
					<NumberFormat value={roundBigNum(poolData.live_data.remainingCapacity)} displayType={'text'} thousandSeparator={true} />
				</div>
			</div>


			<div className='border border-success border-4 w-100 custom-pill-10 p-3'>
				<div className='d-flex justify-content-between'>
					<Text>Staked</Text>
					<div>
						<NumberFormat value={roundBigNum(poolData.live_data.userInfo?.stakedAmount.add(poolData.live_data.userInfo?.compoundAmount))} displayType={'text'} thousandSeparator={true} /> <Text>{poolData.stake_token.symbol}</Text>
					</div>
				</div>

				<div className='d-flex justify-content-between mt-2'>
					<Text >Earned Tokens</Text>
					<div className='d-flex align-items-center'>
						<NumberFormat value={roundBigNum(poolData.live_data.pendingAmount)} displayType={'text'} thousandSeparator={true} />
						<Text> {poolData.stake_token.symbol} </Text>
						<Button className='border-btn ms-1' type='primary' onClick={()=>handleRewardClaim()}>Claim</Button>
					</div>
				</div>
				{
					poolData.is_reflection && 
					<div className='d-flex justify-content-between mt-2'>
						<Text >Earned Reflections</Text>
						<div className='d-flex align-items-center'>
							<NumberFormat value={roundBigNum(poolData.live_data.reflectionReward)} displayType={'text'} thousandSeparator={true}  />
							<Text > {poolData.reflection_token?.symbol} </Text>
							<Button className='border-btn ms-1' type='primary' onClick={()=>handleReflectionClaim()}>Claim</Button>
						</div>
					</div>
				}
				<div className='d-flex mt-2'>
					<StakeForm token = { poolData.stake_token } onFinishForm = {(depositAmount)=>onFinishDeposit(depositAmount)}/>
				</div>
			</div>

			<div className='d-flex justify-content-around mt-3'>
				<Button className='border-btn' type='primary' style={{ width:'150px', textAlign:'center'}} onClick={()=>handleCompound()}>COMPOUND</Button>
				<Button className='border-btn' type='primary' style={{ width:'150px', textAlign:'center'}} onClick={()=>handleWithdraw()}>EMERGENCY EXIT</Button>
			</div>

			<div className="social-items d-flex gap-1 justify-content-center">
				{poolData.website && (
					<a href={`https://${poolData.website}`} target={'_blank'} rel="noreferrer">
						<FaGlobe className="social-icon" />
					</a>
				)}

				{poolData.twitter && (
					<a href={`https://${poolData.twitter}`} target={'_blank'} rel="noreferrer">
						<FaTwitter className="social-icon" />
					</a>
				)}

				{poolData.telegram && (
					<a href={`https://${poolData.telegram}`} target={'_blank'} rel="noreferrer">
						<FaTelegram className="social-icon" />
					</a>
				)}
				{poolData.discord && (
					<a href={`https://${poolData.discord}`} target={'_blank'} rel="noreferrer">
						<FaDiscord className="social-icon" />
					</a>
				)}

				{poolData.youtube && (
					<a href={`https://${poolData.youtube}`} target={'_blank'} rel="noreferrer">
						<FaYoutube className="social-icon" />
					</a>
				)}

				{poolData.reddit && (
					<a target={'_blank'} href={`https://${poolData.reddit}`} rel="noreferrer">
						<FaReddit className="social-icon" />
					</a>
				)}
			</div>
		</div>
	)
}

export default PoolItem
