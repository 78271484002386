import { useAccountInfo } from 'hooks/AccountInfoProvider'
import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import { useAccount } from 'wagmi'

// project imports

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {

	const { isConnected } = useAccount()
	const { accountInfo , isLoading } = useAccountInfo()
	const navigate = useNavigate()
	const sign = async () => {
		navigate('/')
	}

	console.log('process.env.REACT_APP_LOGIN_TOKEN_BALANCE ==>', process.env.REACT_APP_LOGIN_TOKEN_BALANCE)

	useEffect(()=>{
		if(isConnected){
			if(accountInfo.tokenBalance >= Number(process.env.REACT_APP_LOGIN_TOKEN_BALANCE)){
				sign()
			}else{
				navigate('/deposit')
			}
		}else{
			navigate('/auth')
		}
	}, [isConnected, accountInfo.tokenBalance, isLoading])


	return (
		<>
			<Outlet />
		</>
	)
}

export default MinimalLayout
