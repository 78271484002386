import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import PoolItem from '../PoolItem'
import { useAdminPool } from 'hooks/AdminPoolProvider'

const PoolList = () => {

	const { pools:poolItems } = useAdminPool()

	const [searchPoolAddress, setSearchPoolAddress] = useState('')
    
	const filterCancelPool = () => {
		// filterCancelPool
	}

	return (
		<div className='p-2'>
			<div>
				<div className="d-flex flex-column align-items-center w-100">
					<label className="fs-3"> Pool creation fees </label>
					<label className="text-decoration-underline text-center"> 365 day = 4 BNB | 180 day = 3 BNB | 90 day = 2 BNB | 30 day = 1 BNB</label>
				</div>
				<label>
									All staking pools created using the Financial Services platform
									are subject to Terms and Conditions. Please click{' '}
					<Link to={'/disclaimers'} style={{ color: 'blue' }}>here</Link>{' '}to review and accept these before creating pools for yourproject.
					<br /> Ensure the Pool Creator contract address below is exempt from any token transfer taxes before pool creation. Ensure all pool contract addresses are also exempt from any token transfer taxes.
					<br /> Pool Creator contract address:{' '}
					{/* {process.env.REACT_APP_FSPFACTORY_ADDRESS} */}
					{
						// contractsMap.testnet.fspFactory
					}
					<br />
					<br /> A calculator is available here to help plan your staking pools.
				</label>
			</div>

			<label className="pt-4 fs-3">
				Pools created
			</label>
			<div className="row align-items-center">
				<Input
					className="col-sm-9 mt-2"
					value={searchPoolAddress}
					onChange={(e) => setSearchPoolAddress(e.target.value)}
				/>

				<div className="col-sm-3 mt-2">
					<Button
						type='primary'
						className="border-btn m-auto text-nowrap"
						onClick={() => filterCancelPool()}
					>
						Cancel Pool
					</Button>
				</div>
			</div>

			<div className='d-flex flex-column gap-4 py-5'>
				{
					poolItems.map((item:any, index:number)=><PoolItem key = {index} pool={item} />)
				}
			</div>
		</div>
		
	)
}

export default PoolList