import React, { lazy } from 'react'

// project imports
// ==============================|| ERROR ROUTING ||============================== //

const ErrorRoutes = [
	{
		path: '*',
		element: <div>Error</div>
	}
]

export default ErrorRoutes
