import React, { useEffect, useState } from 'react'
import { Content } from 'antd/lib/layout/layout'
import { LoadingOutlined } from '@ant-design/icons'
import { ethers } from 'ethers'
import useAuth from 'rftxStore/auth/auth.hook'
import GovernanceJSON from '../Governance.json'
import LoanJSON from '../Loan.json'
import config from '../config.json'

import { Form, Input, Modal, Spin, Row, Col, Divider } from 'antd'
import { useAccount, useNetwork } from 'wagmi'
import LoanItem from '../LoanItem'
import { PaymentPeriods, Status } from '../constants'
import LendingBorrowingContext from '../context'
import FilterBar from '../FilterBar'
import { formatter, filterLoans } from '../../../utils'
import PaybackModal from './PaybackModal'

const BorrowingView = () => {

	const { address } = useAccount()

	const { auth: { chainId } } = useAuth()

	const [selectedLoan, chooseLoan] = useState()
	const [paybackLoan, choosePaybackLoan] = useState()
	const [borrowStep, setBorrowStep] = useState(0) // 1 --> process of approving token, 2 --> process of creating loan
	const [borrowableLoans, setBorrowableLoans] = useState([])
	const [filter, setFilter] = useState({})

	const [myBorrowedLoans, setMyBorrowedLoans] = useState([])
	const [borrowPermission, setBorrowPermission] = useState(false)
	const [leftCreditAmount, setCreditAmount] = useState(0)
	const [outstandingDebt, setOutstandingDebt] = useState(0)
	const [loading, setLoading] = useState(false)

	const [borrowForm] = Form.useForm()

	useEffect(
		() => {
			if (!window.ethereum) {
				console.log('Please install MetaMask')
			}

			if(chainId && address){
				loadLoanList(chainId)
				checkBorrowerPemission()
			}
		},
		[chainId, address]
	)


	const loadLoanList = async (chainId) => {

		console.log(chainId)

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
		const governanceContractAddress = config[chainId].governance
		const signerAddress = await signer.getAddress()
		const GovernanceContract = new ethers.Contract(
			governanceContractAddress,
			GovernanceJSON.abi,
			signer
		)

		const list = [...await GovernanceContract.getBorrowedLoanListOfUser(signerAddress)]
		for(let i = 0; i < list.length; i++){
			const loan = {...list[i]}
			const LoanContract = new ethers.Contract(
				loan.loan,
				LoanJSON.abi,
				signer
			)
			try {
				const acceptLoanDetail = await LoanContract.acceptLoanMap(address, loan.acceptId)
				loan.outstandingDays = loan.duration.sub(acceptLoanDetail.paidDays)
				loan.lastPaymentTime = acceptLoanDetail.lastPaymentTime
				loan.acceptedAt = acceptLoanDetail.acceptedAt
			}catch(error){
				console.log(error.message)
			}
			list[i] = loan
		}

    
    
		setMyBorrowedLoans(list)

		getOutstandingDebt(list)

		const allLoans = await GovernanceContract.getLoanList(0, 20)
		console.log(allLoans)
		setBorrowableLoans(allLoans)

	}

	const getOutstandingDebt = async (myLoans) => {
		let result = 0
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		for(let i = 0; i < myLoans.length; i++){
			const LoanContract = new ethers.Contract(
				myLoans[i].loan,
				LoanJSON.abi,
				signer
			)
			const acceptLoanDetail = await LoanContract.acceptLoanMap(address, myLoans[i].acceptId)
			result += parseFloat(ethers.utils.formatEther(acceptLoanDetail.principle)) - parseFloat(ethers.utils.formatEther(acceptLoanDetail.paidPrinciple))
		}
    
		setOutstandingDebt(result)
	}
	const checkBorrowerPemission = async () => {

		let available = false

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		try {
			const GovernanceContract = new ethers.Contract(
				config[chainId].governance,
				GovernanceJSON.abi,
				signer
			)
			const whilteListedAmnt = await GovernanceContract.isWhiteListedBorrower()
			const isBlackListed = await GovernanceContract.isBlackListed()
			if(ethers.utils.formatUnits(whilteListedAmnt, 'ether') > 0 && !isBlackListed){
				available = true
			}
			const creditAmount = await GovernanceContract.creditAmount(address)
			setCreditAmount(ethers.utils.formatUnits(creditAmount, 'ether'))
      
		}catch(error){
			console.log(error.message)
			available = false
		}
		setBorrowPermission(available)
	}
 
	const onSubmitBorrowForm = async (values) => {
    
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
		const borrowAmount = ethers.utils.parseUnits(values.borrow_amount, 18)

		setBorrowStep(1)

		try {
			const GovernanceContract = new ethers.Contract(
				config[chainId].governance,
				GovernanceJSON.abi,
				signer
			)

			const tx = await GovernanceContract.accept(selectedLoan.loan, borrowAmount)
			await tx.wait()
			chooseLoan(undefined)
			const creditAmount = await GovernanceContract.creditAmount(address)
			setCreditAmount(ethers.utils.formatUnits(creditAmount, 'ether'))
		}catch(error){
			console.log(error.message)
		}
		setBorrowStep(0)

		loadLoanList(chainId)
    
	}
  
	const findTokeName = (chainId, tokenAddress) => {
		const result = config[chainId].tokens.find(ele => ele.address.toLowerCase() == tokenAddress.toLowerCase())
		return result.name.toUpperCase()
	}

	if(!address){
		return null
	}
  

	if(!borrowPermission){
		return <h1>You do not have permissions to borrow funds at this time, please contact the Rematic Finance team for Details.</h1>
	}

	return (
		<LendingBorrowingContext.Provider value={{ borrowableLoans, setBorrowableLoans, filter, setFilter }}>
			<Content className="container borrow-lending-view">
				<Row className="mt-4">
					<Col md={12} className = "px-4">
						<h2>Borrowed Loans</h2>
						<Divider />
						<h5>Outstanding debt: {formatter.format(outstandingDebt)}</h5>
						<h5>Credit Amount: {formatter.format(leftCreditAmount)}</h5>
						<Divider />
						<Row gutter= {[16, 16]}>
							{myBorrowedLoans.map((ele, index)=> 
								<Col md = {24} key = {index}>
									<LoanItem  
										loanData = {ele}  
										key = {index} 
										actionButtons = {
											<button 
												className="customButton" 
												onClick={()=>{
													choosePaybackLoan(ele)
													// borrowForm.setFieldsValue({...ele, tokenAmount:ethers.utils.formatUnits(ele.tokenAmount, "ether") });
												}}>
Pay Back
											</button>
										}/>
								</Col>)
							}
						</Row>
					</Col>
					<Col md={12} style = {{ borderLeft:'solid 2px rgb(12, 167, 136)'}} className = "px-4">
						<h2>Borrowable Loans</h2>
						<FilterBar />
						<Row gutter= {[16, 16]} className = "mt-2">
							{filterLoans(borrowableLoans, filter)
								.filter((ele)=>ele.status != 0 && parseFloat(ethers.utils.formatUnits(ele.availableAmount, 'ether')) > 0)
								.map((ele, index)=> 
									<Col md = {24} key = {index}>
										<LoanItem 
											loanData = {ele} 
											key = {index}  
											actionButtons = {
												<>
													<button 
														className="customButton" 
														onClick={()=>{
															chooseLoan(ele)
															borrowForm.setFieldsValue({...ele, tokenAmount:ethers.utils.formatUnits(ele.tokenAmount, 'ether') })
														}}>
                                              Borrow
													</button>
												</>
											}/>
									</Col>)
							}
						</Row>
					</Col>
				</Row>

				<Modal
					title="Borrow"
					centered
					open={selectedLoan}
					footer = {false}
					onOk={() => {
						borrowForm.resetFields()
						chooseLoan(undefined)
					}}
					onCancel={() => {
						borrowForm.resetFields()
						chooseLoan(undefined)
					}}
				>
					<div className="position-relative">
						<div><b>Credit Amount: {formatter.format(leftCreditAmount)}</b></div>
						{selectedLoan && <>
							<div className="text-capitalize">
                  token: {findTokeName(chainId, selectedLoan.token)}
							</div>
							<div className="text-capitalize">
                  Loanable Contract Max: {formatter.format(ethers.utils.formatUnits(selectedLoan.tokenAmount, 'ether'))}
							</div>
							<div className="text-capitalize">
                  Available Funds: {formatter.format(ethers.utils.formatUnits(selectedLoan.availableAmount, 'ether'))}
							</div>
							<div className="text-capitalize">
                  duration: {ethers.utils.formatUnits(selectedLoan.duration, 0)} Days
							</div>
							<div className="text-capitalize">
                  payment Period: {PaymentPeriods[selectedLoan.paymentPeriod]}
							</div>
							<div className="text-capitalize">
                  APR: {ethers.utils.formatUnits(selectedLoan.aPRInerestRate, 0)} %
							</div>
							<div className="text-capitalize">
                  owner: {selectedLoan.owner.slice(0, 4) + '...' + selectedLoan.owner.slice(-4)}
							</div>
							<div className="text-capitalize">
                  status: {Status[selectedLoan.status]}
							</div>
						</>}
						<Form 
							form={borrowForm} 
							labelCol= {{ span: 9 }} 
							wrapperCol = {{ span: 18 }} 
							onFinish = {onSubmitBorrowForm} 
							className="mt-4" 
							onValuesChange = {(values)=>{
								if(parseFloat(values.borrow_amount) < 0){
									borrowForm.setFieldValue('borrow_amount', 0)
								}
							}}>
							<Form.Item
								label="Borrow Amount"
								name="borrow_amount"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								{selectedLoan && <Input type="number" max={ethers.utils.formatUnits(selectedLoan.tokenAmount, 18)} suffix = "BUSD" />}
							</Form.Item>
							<div className="d-flex flex-row-reverse">
								<button type="submit" className="customButton px-4">Borrow</button>
							</div>
						</Form>
					</div>
					{
						borrowStep > 0 &&
						<div className="position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center">
							<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}/>
						</div>
					}
				</Modal>

				<PaybackModal open = {paybackLoan} loan = {paybackLoan} onClose = {()=>{ choosePaybackLoan(undefined)}} />

			</Content>
		</LendingBorrowingContext.Provider>
	)
  
}

export default BorrowingView
