import React from 'react'
import { ethers } from 'ethers'
import useAuth from 'rftxStore/auth/auth.hook'
import config from '../config.json'
import { Durations, PaymentPeriods, Status, tokenLogos } from '../constants'
import { useAccount, useNetwork } from 'wagmi'
import { formatter } from '../../../utils'
import { Button } from 'antd'

const TokenLogo = ({tokenName, className, style}) => {
	return (
		<div className={`d-flex justify-center align-items-center flex-column ${className}`} style = {{ cursor:'pointer', color:'#000', ...style}}>
			<img src ={tokenLogos[tokenName]} alt = {tokenName} width = {50} height = {50} style = {{ borderRadius:'50%', border:'solid 1px #0ca788' }} />
			<b>{tokenName}</b>
		</div>
	)
}

export default ({ loanData, actionButtons }) => {

	const { address, connector, isConnected } = useAccount()

	const { auth: { chainId } } = useAuth()

	if(!address) return null

	const findTokeName = (chainId, tokenAddress) => {
		const result = config[chainId].tokens.find((ele) => ele.address.toLowerCase() == tokenAddress.toLowerCase())
		return result.name.toUpperCase()
	}

	return (
		<div className="card p-3" style={{ border: '5px solid #0ca788', backgroundColor:'#cdf0ea', borderRadius:'20px', fontSize:'22px'}}>
			<div className='position-relative'>
				<a href={`${config[chainId].explorer}/address/${loanData.token}`} target={'_blank'} rel="noreferrer">
					<TokenLogo tokenName={ findTokeName(chainId, loanData.token) } className = "position-absolute top-0 end-0" />
				</a>
				<div className="text-capitalize">
                loan: <a href={`${config[chainId].explorer}/address/${loanData.loan}`} target={'_blank'} rel="noreferrer">{loanData.loan.slice(0, 4) + '...' + loanData.loan.slice(-4)}</a>
				</div>
				<div className="text-capitalize">
                Loanable Contract Max: {formatter.format(ethers.utils.formatUnits(loanData.tokenAmount, 'ether'))}
				</div>
				<div className="text-capitalize">
                Available Funds: {formatter.format(ethers.utils.formatUnits(loanData.availableAmount, 'ether'))}
				</div>
				<div className="text-capitalize">
                duration: {ethers.utils.formatUnits(loanData.duration, 0)} Days
				</div>
				<div className="text-capitalize">
                payment Period: {PaymentPeriods[loanData.paymentPeriod]}
				</div>
				<div className="text-capitalize">
                Number of payments: { Math.floor(ethers.utils.formatUnits(loanData.duration, 0) / loanData.paymentPeriod) }
				</div>
				{loanData.outstandingDays && 
                    <div className="text-capitalize">
                    Number of payments Remain: { Math.floor(ethers.utils.formatUnits(loanData.outstandingDays, 0) / loanData.paymentPeriod) }
                    </div>
				}
				<div className="text-capitalize">
                APR: {ethers.utils.formatUnits(loanData.aPRInerestRate, 0)} %
				</div>
				<div className="text-capitalize">
                owner: <a href={`${config[chainId].explorer}/address/${loanData.owner}`} target={'_blank'} rel="noreferrer">{loanData.owner.slice(0, 4) + '...' + loanData.owner.slice(-4)}</a>
				</div>
				<div className="text-capitalize">
                status: {Status[loanData.status]}
				</div>
				<div className="d-flex  flex-row-reverse w-100 mt-4">
					{
						actionButtons
					}
					{/* {address.toLowerCase() == loanData.owner.toLowerCase() && 
                    <button type="button" className="customButton" disabled = {loanData.status != 1} onClick={()=>{
                        chooseEditableLoan(loanData);
                        editForm.setFieldsValue({...loanData });
                    }}>Edit</button>
                } */}
				</div>
			</div>
		</div> 
	)
}