import React from 'react'
import PoolCreationForm from 'components/AdminStakingPool/PoolCreationForm'
import { AdminPoolProvider } from 'hooks/AdminPoolProvider'
import PoolList from './PoolList'

const AdminStakingPool = () => {
	return (
		<AdminPoolProvider>
			<div className="container py-5">
				<div className="row">
					<div className="col-lg-6">
						<PoolCreationForm />
					</div>
					<div className="col-lg-6 created-pool pt-5">
						<PoolList />
					</div>
				</div>
			</div>
	0	</AdminPoolProvider>
	)
}

export default AdminStakingPool
