import React, { useEffect } from 'react'

import { Modal, Form, Spin, Divider, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ethers } from 'ethers'
import { useAccount, useNetwork } from 'wagmi'
import { PaymentPeriods, Status } from '../constants'
import useAuth from 'rftxStore/auth/auth.hook'
import LoanJSON from '../Loan.json'
import ERC20JSON from '../ERC20.json'
import {formatter} from '../../../utils'
import config from '../config.json'
import moment from 'moment'


export default ({ open, loan, onClose }) => {

	const { address, connector, isConnected } = useAccount()

	const { auth:{chainId} } = useAuth()

	const [form] = Form.useForm()
	const [loading, setLoading] = React.useState(false)
	const [loanDetail, setLoanDetail] = React.useState({
		principle:0,
		outstandingPrinciple:0,
		interest:0,
		outstandingInterest:0,
		nextPaymentDate:0,
		outstandingDays:0
	})

	const [paymentBreakdown, setPaymentBreakdown] = React.useState({
		payoff: {
			eligible:false,
			principleToPay:0,
			interestToPay:0,
			feeToPay:0,
			total:0,
		},
		payback:{
			eligible:false,
			principleToPay:0,
			interestToPay:0,
			feeToPay:0,
			total:0,
		}
	})

	useEffect(() => {
		if(loan){
			if (!window.ethereum) {
				console.log('Please install MetaMask')
			}
    
			if(chainId && address){
				loadLoanDetail()
			}
		}
	}, [open, loan])

	const loadLoanDetail = async () => {

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		const LoanContract = new ethers.Contract(
			loan.loan,
			LoanJSON.abi,
			signer
		)

        
		try {
			console.log('getpayback amount ==>',loan.acceptId, address)
			const payback = await LoanContract.getPaybackAmount(loan.acceptId, address)
			const payoff = await LoanContract.getPayOffAmount(loan.acceptId, address)

            

			const acceptLoanDetail = await LoanContract.acceptLoanMap(address, loan.acceptId)

			const paymentDue = await LoanContract.paymentDue() // in hours
			const lastPaymentTime = acceptLoanDetail.lastPaymentTime // in seconds
			const paymentPeriod = await LoanContract.paymentPeriod() // in days

			const today = moment()
			const lastPaymentDay = moment.unix(ethers.utils.formatUnits(acceptLoanDetail.lastPaymentTime, 0))
			// const diffDays = today.diff(lastPaymentDay, 'days');
			const diffDays = today.diff(lastPaymentDay, 'hours')
			let eligible = false
			if(diffDays > ethers.utils.formatUnits(paymentPeriod.sub(paymentDue.div(24)), 0)){
				eligible = true
			}

			setPaymentBreakdown({
				payback: {
					eligible,
					principleToPay:ethers.utils.formatEther(payback.principleToPay),
					interestToPay:ethers.utils.formatEther(payback.interestToPay),
					feeToPay:ethers.utils.formatEther(payback.feeToPay),
					extraPaneltyToPay:ethers.utils.formatEther(payback.extraPaneltyToPay),
					total: ethers.utils.formatEther(payback.principleToPay.add(payback.interestToPay).add(payback.feeToPay).add(payback.extraPaneltyToPay))
				},
				payoff:{
					eligible:true,
					principleToPay:ethers.utils.formatEther(payoff.principleToPay),
					interestToPay:ethers.utils.formatEther(payoff.interestToPay),
					feeToPay:ethers.utils.formatEther(payoff.feeToPay),
					extraPaneltyToPay:ethers.utils.formatEther(payoff.extraPaneltyToPay),
					total: ethers.utils.formatEther(payoff.principleToPay.add(payoff.interestToPay).add(payoff.feeToPay).add(payback.extraPaneltyToPay))
				}
			})

			const totalIrt = loan.tokenAmount.mul(loan.aPRInerestRate).div(100).mul(loan.duration).div(365)

			setLoanDetail(
				{
					principle: ethers.utils.formatEther(acceptLoanDetail.principle),
					outstandingPrinciple: ethers.utils.formatEther(acceptLoanDetail.principle.sub(acceptLoanDetail.paidPrinciple)),
					interest: ethers.utils.formatEther(totalIrt),
					outstandingInterest: ethers.utils.formatEther( totalIrt.sub(acceptLoanDetail.paidInterest)),
					outstandingDays: ethers.utils.formatUnits(loan.duration - acceptLoanDetail.paidDays, 0),
					nextPaymentDate: moment.unix(parseInt(ethers.utils.formatUnits(acceptLoanDetail.lastPaymentTime, 0)) + loan.paymentPeriod * 3600 * 24).format('MM/DD/YYYY'),
				}
			)

		}catch(error){
			console.log(error.message)
		}
	}

	const findTokeName = (chainId, tokenAddress) => {
		const result = config[chainId].tokens.find(ele => ele.address.toLowerCase() == tokenAddress.toLowerCase())
		return result.name.toUpperCase()
	}


	const payback = async (loan) => {

		setLoading(true)
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
    
		const LoanContract = new ethers.Contract(
			loan.loan,
			LoanJSON.abi,
			signer
		)
    
		const BUSDContract = new ethers.Contract(
			loan.token,
			ERC20JSON.abi,
			signer
		)
    
		try {
            
			const paybackAmountObj = await LoanContract.getPaybackAmount(loan.acceptId, address)
			const paybackAmount = paybackAmountObj.principleToPay.add(paybackAmountObj.interestToPay).add(paybackAmountObj.feeToPay).add(paybackAmountObj.extraPaneltyToPay)
			let tx = await BUSDContract.approve(loan.loan, paybackAmount)
			await tx.wait()
			tx = await LoanContract.payBack(loan.acceptId, paybackAmount)
			await tx.wait()
		}catch(error){
			console.log(error.message)
		}
    
		await loadLoanDetail()

		setLoading(false)
    
	}


	const payOff = async (loan) => {

		setLoading(true)
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
    
		const LoanContract = new ethers.Contract(
			loan.loan,
			LoanJSON.abi,
			signer
		)
    
		const BUSDContract = new ethers.Contract(
			loan.token,
			ERC20JSON.abi,
			signer
		)
    
		try {
			const paybackAmountObj = await LoanContract.getPayOffAmount(loan.acceptId, address)
			const paybackAmount = paybackAmountObj.principleToPay.add(paybackAmountObj.interestToPay).add(paybackAmountObj.feeToPay).add(paybackAmountObj.extraPaneltyToPay)
			let tx = await BUSDContract.approve(loan.loan, paybackAmount)
			await tx.wait()
			tx = await LoanContract.payOff(loan.acceptId, paybackAmount)
			await tx.wait()
		}catch(error){
			console.log(error.message)
		}

		await loadLoanDetail()
        
		setLoading(false)
    
	}

	if(!loan) return null

	return (
		<Modal
			title="Payback"
			width="600px"
			centered
			open={open}
			footer = {false}
			onOk={() => {
				onClose()
			}}
			onCancel={() => {
				onClose()
			}}
		>
			<div className="position-relative" style={{fontSize:'22px'}}>
                  
				<div className="text-capitalize">
                  Principle: {formatter.format(loanDetail.principle)}
				</div>

				<div className="text-capitalize">
                  Outstanding Principle: {formatter.format(loanDetail.outstandingPrinciple)}
				</div>
				<div className="text-capitalize">
                  Total Interest: {formatter.format(loanDetail.interest)}
				</div>
				<div className="text-capitalize">
                  Outstanding Interest: {formatter.format(loanDetail.outstandingInterest)}
				</div>

				<div className="text-capitalize">
                  Next Payment Due Date: {loanDetail.nextPaymentDate}
				</div>
				<div className="text-capitalize">
                  Outstanding Days: {loanDetail.outstandingDays}
				</div>
				<Divider />
				<div className='w-100'>
					<Row gutter={[4, 4]}>
						<Col md = {10}>
							<div className='d-flex justify-content-between'><span>Principle To Pay:</span> <span>{formatter.format(paymentBreakdown.payback.principleToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Interest To Pay:</span> <span>{formatter.format(paymentBreakdown.payback.interestToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Fee To Pay:</span> <span>{formatter.format(paymentBreakdown.payback.feeToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Penalty Fee:</span> <span>{formatter.format(paymentBreakdown.payback.extraPaneltyToPay)}</span></div>
							<Divider />
							<div className='d-flex justify-content-between'><span>Total:</span> <span>{ formatter.format(paymentBreakdown.payback.total)}</span></div>
							<button 
								className="customButton mt-3 ms-auto" 
								disabled = {!paymentBreakdown.payback.eligible}
								onClick={()=>{
									payback(loan)
								}}>
                                Make Payment    
							</button>
						</Col>
						<Col md = {4}></Col>
						<Col md = {10}>
							<div className='d-flex justify-content-between'><span>Principle To Pay:</span> <span>{formatter.format(paymentBreakdown.payoff.principleToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Interest To Pay:</span> <span>{formatter.format(paymentBreakdown.payoff.interestToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Fee To Pay:</span> <span>{formatter.format(paymentBreakdown.payoff.feeToPay)}</span></div>
							<div className='d-flex justify-content-between'><span>Penalty Fee:</span> <span>{formatter.format(paymentBreakdown.payoff.extraPaneltyToPay)}</span></div>
							<Divider />
							<div className='d-flex justify-content-between'><span>Total:</span> <span>{ formatter.format(paymentBreakdown.payoff.total)}</span></div>
							<button 
								className="customButton mt-3 ms-auto" 
								onClick={()=>{
									payOff(loan)
								}}>
                                Pay Off
							</button>
						</Col>
					</Row>
				</div>
				{
					loading > 0 &&
					<div className="position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center">
						<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}/>
					</div>
				}
			</div>
		</Modal>
	)
}