import React, { lazy } from 'react'

// project imports
import Loadable from 'components/Loader/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import DepositView from 'views/DepositView'

const AuthenticationView = Loadable(lazy(() => import('views/Authentication')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = [
	{
		path: '/auth',
		element: <MinimalLayout />,
		children: [
			{
				path: '',
				element: <AuthenticationView />
			}
		]
	},
	{
		path: '/deposit',
		element: <MinimalLayout />,
		children: [
			{
				path: '',
				element: <DepositView />
			}
		]
	},
]

export default AuthenticationRoutes
