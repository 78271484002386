import React, { useEffect, useState } from 'react'
import { Content } from 'antd/lib/layout/layout'
import { LoadingOutlined } from '@ant-design/icons'
import { ethers } from 'ethers'
import useAuth from 'rftxStore/auth/auth.hook'
import Staking401kInfo from '../../../components/Staking401kInfo'
import axios from 'axios'
import GovernanceJSON from '../Governance.json'
import ERC20JSON from '../ERC20.json'
import LoanJSON from '../Loan.json'
import config from '../config.json'
import LendingBorrowingContext from '../context'
import swal from 'sweetalert'
import { Button, Checkbox, Form, Input, Select, Modal, Spin, Grid, Row, Col } from 'antd'
import { useAccount, useNetwork } from 'wagmi'
import LoanItem from '../LoanItem'
import FilterBar from '../FilterBar'
import { formatter, filterLoans } from '../../../utils'

const { Option } = Select

const LendingView = () => {

	const { address, connector, isConnected } = useAccount()

	const { auth: {chainId} } = useAuth()

	// const { loans, setLoans } = React.useContext(LendingBorrowingContext);

	const [createLoanModalOpen, setCreateLoanModalOpen] = useState(false)
	const [selectedEditableLoan, chooseEditableLoan] = useState()
	const [createLoanStep, setCreateLoanStep] = useState(0) // 1 --> process of approving token, 2 --> process of creating loan
	const [editingProcess, setEditingProcess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loans, setLoans] = useState([])
	const [filter, setFilter] = useState({})
	const [myLoans, setMyLoans] = useState([])
	const [lenderPermission, setLenderPermission] = useState([])

	const [form] = Form.useForm()
	const [editForm] = Form.useForm()

	useEffect(
		() => {
			if (!window.ethereum) {
				console.log('Please install MetaMask')
			}

			if(chainId){
				loadLoanList(chainId)
				checkLenderPemission()
			}
		}, 
		[chainId, address]
	)


	const loadLoanList = async (chainId) => {

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
		const governanceContractAddress = config[chainId].governance
		const signerAddress = await signer.getAddress()
		const GovernanceContract = new ethers.Contract(
			governanceContractAddress,
			GovernanceJSON.abi,
			signer
		)
		const list = await GovernanceContract.getOwnedLoanListOfUser(signerAddress)
		setMyLoans(list)
		console.log('list:', list)
		const allLoans = await GovernanceContract.getLoanList(0, 10)
		console.log('allLoans:', allLoans)
		setLoans(allLoans)
	}

	const checkLenderPemission = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		let available = false

		try {
			const GovernanceContract = new ethers.Contract(
				config[chainId].governance,
				GovernanceJSON.abi,
				signer
			)
			const isWhilteListed = await GovernanceContract.isWhiteListedLender()
			const isBlackListed = await GovernanceContract.isBlackListed()
			if(isWhilteListed && !isBlackListed){
				available = true
			}
		}catch(error){
			console.log(error.message)
		}
		setLenderPermission(available)
	}

	const onSubmitForm = async (values) => {

		const approved = await approveToken(values.token, values.tokenAmount)
		if(approved){
			await createLoan(values)
			form.resetFields()
		}
	}

	const onSubmitEditForm = async (values) => {

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		const LoanContract = new ethers.Contract(
			selectedEditableLoan.loan,
			LoanJSON.abi,
			signer
		)

		setEditingProcess(true)

		const tx = await LoanContract.updateDurationPaymentPeriodAPRInerestRate(values.duration, values.paymentPeriod, values.aPRInerestRate)
		await tx.wait()

		setEditingProcess(false)

		loadLoanList(chainId)
	}

	const approveToken = async (token, tokenAmount, callback) => {
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()
		const BUSDContract = new ethers.Contract(
			token,
			ERC20JSON.abi,
			signer
		)
		try{
			setCreateLoanStep(1)
			const tx = await BUSDContract.approve(config[chainId].governance, ethers.utils.parseEther(tokenAmount))
			await tx.wait()
			return true
		}catch(error){
			console.log(error.message)
			return false
		}
	}

	const createLoan = async (values) => {
		// creating loan
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		try {
			setCreateLoanStep(2)
			const GovernanceContract = new ethers.Contract(
				config[chainId].governance,
				GovernanceJSON.abi,
				signer
			)
			const tx = await GovernanceContract.createLoan(values.token, ethers.utils.parseEther(values.tokenAmount), values.duration, values.paymentPeriod, values.aPRInerestRate )
			await tx.wait()
			setCreateLoanModalOpen(false)
			swal('Good job!', 'You created a loan successfully', 'success')
			loadLoanList(chainId)
		}catch(error){
			console.log(error.message)
		}
		setCreateLoanStep(0)
	}

	const cancelLoan = async (loanDetail) => {

		setLoading(true)

		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const signer = provider.getSigner()

		try {
			const LoanContract = new ethers.Contract(
				loanDetail.loan,
				LoanJSON.abi,
				signer
			)
			const tx = await LoanContract.cancel()
			await tx.wait()
			loadLoanList(chainId)
		}catch(error){
			console.log(error.message)
		}
		setLoading(false)
	}

	if(!address){
		return null
	}

	if(!lenderPermission){
		return <h1>You do not have permissions to create loans at this time, please contact the Rematic Finance team for Details.</h1>
	}

	console.log('myLoans ===>', myLoans)

	return (
		<LendingBorrowingContext.Provider value={{loans, setLoans, filter, setFilter}}>
			<Content className="container borrow-lending-view">
				<Row className="mt-4">
					<Col md={12} className = "px-4">
						<h2>My Loans</h2>
						<div style={{ height:'50px'}} className = "mt-4">
							<button className="customButton" onClick={()=>setCreateLoanModalOpen(true)}>Create a loan</button>
						</div>
						<Row gutter= {[16, 16]} className = "mt-4">
							{myLoans.map((ele, index)=> 
								<Col md = {24} key = {index}>
									<LoanItem  
										loanData = {ele}  
										actionButtons = {
											<div className="w-100 d-flex justify-content-between">
												<button type="button" className="customButton px-4" disabled = {ele.status != 1} onClick={()=>{
													chooseEditableLoan(ele)
													editForm.setFieldsValue({...ele, tokenAmount:ethers.utils.formatUnits(ele.tokenAmount, 'ether') })
												}}>
Edit
												</button>
												{ 
													ele.status == 1 && <button type="button" className="customButton px-4" disabled ={loading} onClick={()=>{
														cancelLoan(ele)
													}}>
Cancel
													</button>
												}
											</div>}
									/>
								</Col>)
							}
						</Row>
					</Col>
					<Col md={12} style = {{ borderLeft:'solid 2px rgb(12, 167, 136)'}} className = "px-4">
						<h2>Available Loans</h2>
						<FilterBar />
						<Row gutter= {[16, 16]} className = "mt-4">
							{filterLoans(loans, filter).filter((ele)=>ele.owner.toLowerCase() != address.toLowerCase())
								.map((ele, index)=> 
									<Col md = {24} key = {index}>
										<LoanItem  loanData = {ele} />
									</Col>)
							}
						</Row>
					</Col>
				</Row>

				<Modal
					title="Create A Loan"
					centered
					open={createLoanModalOpen}
					footer = {false}
					onOk={() => {
						form.resetFields()
						setCreateLoanModalOpen(false)
					}}
					onCancel={() => {
						form.resetFields()
						setCreateLoanModalOpen(false)
					}}
				>
					<div className="position-relative">
						<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitForm}>
							<Form.Item
								label="Token"
								name="token"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Select style={{ width: 120 }}>
									{
										config[chainId].tokens.map((token)=><Option key = {token.address} value={token.address}>{token.name}</Option>)
									}
								</Select>
							</Form.Item>
							<Form.Item
								label="Token Amount"
								name="tokenAmount"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Input type="number" min={10} max = {10000} />
							</Form.Item>
							<Form.Item
								label="Duration"
								name="duration"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Select style={{ width: 120 }}>
									<Option value={30}>30 Days</Option>
									<Option value={90}>90 Days</Option>
									<Option value={180}>180 Days</Option>
									<Option value={365}>365 Days</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="Payment Period"
								name="paymentPeriod"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Select style={{ width: 120 }}>
									<Option value={7}>Weekly</Option>
									<Option value={14}>BiWeekly</Option>
									<Option value={30}>Monthly</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="APRInerestRate"
								name="aPRInerestRate"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Input type="number" min={5} max={500} suffix = "%" />
							</Form.Item>
							<div className="d-flex flex-row-reverse">
								<button type="submit" className="customButton px-4">Create</button>
							</div>
						</Form>
					</div>
					{
						createLoanStep > 0 &&
<div className="position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center flex-column">
	<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}/>
	<div className="mt-2">
		{createLoanStep == 1?<h5>Approving BUSD ...</h5>:createLoanStep == 2?<h5>Creating a loan...</h5>:''}
	</div>
</div>
					}
				</Modal>

				<Modal
					title="Edit A Loan"
					centered
					open={selectedEditableLoan}
					footer = {false}
					onOk={() => {
						editForm.resetFields()
						chooseEditableLoan(undefined)
					}}
					onCancel={() => {
						editForm.resetFields()
						chooseEditableLoan(undefined)
					}}
				>
					<div className="position-relative">
						<Form form={editForm} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitEditForm}>
							<Form.Item
								label="Token"
								name="token"
							>
								<Select style={{ width: 120 }} disabled>
									{
										config[chainId].tokens.map((token)=><Option key={token.name} value={token.address}>{token.name}</Option>)
									}
								</Select>
							</Form.Item>
							<Form.Item
								label="Token Amount"
								name="tokenAmount"
								required
							>
								<Input disabled/>
							</Form.Item>
							<Form.Item
								label="Duration"
								name="duration"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Select style={{ width: 120 }}>
									<Option value={30}>30 Days</Option>
									<Option value={90}>90 Days</Option>
									<Option value={180}>180 Days</Option>
									<Option value={365}>365 Days</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="Payment Period"
								name="paymentPeriod"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Select style={{ width: 120 }}>
									<Option value={7}>Weekly</Option>
									<Option value={14}>BiWeekly</Option>
									<Option value={30}>Monthly</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="APRInerestRate"
								name="aPRInerestRate"
								required
								rules={[{ required: true, message: 'required!' }]}
							>
								<Input type="number" min={5} max={500} suffix = "%" />
							</Form.Item>
							<div className="d-flex flex-row-reverse">
								<button type="submit" className="customButton px-4">Update</button>
							</div>
						</Form>
					</div>
					{
						editingProcess &&
						<div className="position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center">
							<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}/>
						</div>
					}
				</Modal>
			</Content>
		</LendingBorrowingContext.Provider>
	)

}

export default LendingView
