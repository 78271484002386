import { createSlice } from '@reduxjs/toolkit'
// import localStore from "helpers/localstore"

const initialState = {
	trackAddress:[],
	chainId:0,
	serviceType:0,
	sidebarType:0,
	sidebarToggle:false,
	headerType:0,
	contractsLoaded:false
}

const reducers = {
	setTrackAddress: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.trackAddress = payload
	},
	setHeaderType: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.headerType = payload
	},
	setChainId: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.chainId = payload
	},
	setServiceType: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.serviceType = payload
	},
	setSidebarType: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.sidebarType = payload
	},
	setSidebarToggle: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.sidebarToggle = payload
	},
	setContractsLoaded: (state:any, action: { payload:any }) => {
		const { payload } = action
		state.contractsLoaded = payload
	},
}

const slice = createSlice({ name: 'auth', initialState, reducers })

export const { setTrackAddress, setHeaderType, setChainId, setServiceType, setSidebarType, setSidebarToggle, setContractsLoaded  } = slice.actions
export default slice.reducer
