import { useMemo } from 'react'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, combineReducers, compose, configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/auth.reducer'
import watchAuthSaga from './auth/auth.saga'

function initStore(initialState) {
	const sagaMiddleware = createSagaMiddleware()

	const rootReducer = combineReducers({
		authReducer,
	})

	const store = configureStore({
		reducer: rootReducer,
		preloadedState: initialState,
		middleware: [sagaMiddleware]
	})
	sagaMiddleware.run(watchAuthSaga)
	return store
}

export const initializeStore = (preloadedState) => {
	let _store = store ?? initStore(preloadedState)

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState
		})
		// Reset the current store
		store = undefined
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store
	// Create the store once in the client
	if (!store) store = _store

	return _store
}

// export function useStore(initialState) {
//     const store = useMemo(() => initializeStore(initialState), [initialState]);
//     return store;
// }

var store = initStore({})

export default store
