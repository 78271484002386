import { BigNumber, ethers, Signer } from 'ethers'
import { NULL_ADDRESS } from '../utils'
import swal from 'sweetalert'
import { getContractsData } from 'utils/contracts'
import { FetchSignerResult } from '@wagmi/core'
import { PoolLiveDataType } from 'utils/types'

export const findTokenInfo = async (
	tokenAddress:string,
	signer?:FetchSignerResult<Signer>
) => {
	if (!signer) throw new Error('Please connect Wallet')
	const tokenABI = [
		'function symbol() public view returns (string)',
		'function name() public view returns(string)'
	]
	const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer)
	const symbol = await tokenContract.symbol()
	return {
		address:tokenAddress,
		symbol
	}
}
