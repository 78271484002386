import React from 'react'
import ReactDOM from 'react-dom/client'
import { Button, ConfigProvider } from 'antd'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './rftxStore'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'antd/dist/reset.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<ConfigProvider 
				theme={{
					token: {
						colorPrimary:'#0ca788',
						colorSuccess:'#055242'
					},
				}}>
				<App />

			</ConfigProvider>
		</BrowserRouter>
	</Provider>
)

reportWebVitals()
