import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { AccountInfoType } from 'utils/types'
import { useAccount, useSigner } from 'wagmi'
import useAuth from 'rftxStore/auth/auth.hook'
import { getContractsData } from 'utils/contracts'
import { get401kReward, getPartitionEarned, getRFXStakedAmount, getReflectionsFromDefaultDivTracker, getTokenBalance } from 'web3Client/account'
import { getHistoricalBalance, getTokenTransactions } from 'api/api-users'
import { utils, writeFile } from 'xlsx'

interface ProviderProps {
    loadState:{
        loadingTxData:boolean,
        loadingChart:boolean,
        loadingAccountInfo:boolean,
    },
    chartData:any[],
    accountInfo:any,
    txHistoryData:any[],
    loadAccountData:()=>void,
    loadTxHistoryData:(from_date?:number, to_date?:number)=>void,
    exportTxData:()=>void,
    loadAccount:()=>void

}

export const AccountContext = createContext<ProviderProps>({
	loadState: {
		loadingTxData: false,
		loadingChart: false,
		loadingAccountInfo: false
	},
	chartData: [],
	accountInfo: undefined,
	txHistoryData: [],
	loadAccountData: function (): void {
		throw new Error('Function not implemented.')
	},
	loadTxHistoryData: function (): void {
		throw new Error('Function not implemented.')
	},
	exportTxData: function (): void {
		throw new Error('Function not implemented.')
	},
	loadAccount: function (): void {
		throw new Error('Function not implemented.')
	},
	
})

interface Props {
    children:React.ReactNode
}
export const AccountProvider = ({ children }:Props) => {

	const [chartData, setChartData] = useState([])
	const [accountInfo, setAccountInfo] = useState<AccountInfoType>({
		totalBalance: 0,
		tokenValue: 0,
		tokenBalance: 0,
		// reflection: {
		// 	earned: 0,
		// },
		// four01kValue: 0,
		mainTokenValue: 0,
		stakingBalance: 0,
		// partitionEarned: 0,
	})
	const [txHistoryData, setTxHistoryData] = useState<any[]>([])
	const [txDataForExport, setTxDataForExport] = useState<any>()
	const [loadState, setLoadState] = useState({
		loadingTxData: false,
		loadingChart: false,
		loadingAccountInfo: false,
	})

	const { auth:{ trackAddress } } = useAuth()
	const { address } = useAccount()
	const { data: signer } = useSigner()
    
	useEffect(() => {
		if(address && signer){
			loadAccount()
		} else {
			setChartData([])
			setTxHistoryData([])
			setAccountInfo({
				totalBalance: 0,
				tokenValue: 0,
				tokenBalance: 0,
				// reflection: {
				// 	earned: 0,
				// },
				// four01kValue: 0,
				mainTokenValue: 0,
				stakingBalance: 0,
				// partitionEarned: 0,
			})
		}
	}, [address, signer])

	const loadAccount = useCallback(async()=>{
		if (!trackAddress || trackAddress.length === 0) {
			setChartData([])
			setTxHistoryData([])
			loadAccountData()
		} else {
			loadAccountData()
			loadChartData()
			loadTxHistoryData()
		}
	}, [address, signer])

	const loadAccountData = useCallback(async ()=>{
		const contractMap = getContractsData()
		const tokenBalance = await getTokenBalance(contractMap.rematicFinance.address, signer)
		// const reflection = await getReflectionsFromDefaultDivTracker(signer)
		// const partitionEarned = await getPartitionEarned(signer)
		// const four01kValue = await get401kReward(signer)
		const stakingBalance = await getRFXStakedAmount(signer)
		setAccountInfo({ ...accountInfo,  tokenBalance, stakingBalance })
	}, [address, signer])

	const loadChartData = async () => {
		const contractMap = getContractsData()
		try {
			setLoadState((prev) => ({ ...prev, loadingChart: true }))

			const { data: chartData } = await getHistoricalBalance({
				address: address,
				contractAddress: trackAddress
					? trackAddress.length > 0
						? trackAddress
						: [contractMap.rematicFinance.address]
					: [contractMap.rematicFinance.address],
				days: 100,
			})
			setChartData(chartData.result)
		} catch (err) {
			console.log(err)
		} finally {
			setLoadState((prev) => ({ ...prev, loadingChart: false }))
		}
	}

	// get transactions history data of tracking smart contracts

	const loadTxHistoryData = async (from_date?:number, to_date?:number) => {
		const contractMap = getContractsData()
		try {
			setLoadState((prev) => ({ ...prev, loadingTxData: true }))

			const { data: txData} = await getTokenTransactions(
				{
					contractAddress: trackAddress
						? trackAddress.length > 0
							? trackAddress
							: [contractMap.rematicFinance.address]
						: [contractMap.rematicFinance.address],
					address: address,
					from_date: from_date,
					to_date: to_date,
				}
			)

			setTxDataForExport(txData)

			const res_data = txData.result.map((txItem:any, index:number) => {
				return {
					key: index,
					txnhash: txItem.txhash,
					age: txItem.timestamp,
					from: {
						address: txItem.from_address,
						type: txItem.type === 'IN' ? true : false,
					},
					to: {
						address: txItem.to_address,
						type: txItem.type === 'OUT' ? true : false,
					},
					value: txItem.value.toFixed(2),
					token: {
						name: txItem.name,
						address: txItem.address,
						symbol: txItem.symbol,
					},
				}
			})

			setTxHistoryData(res_data)
		} catch (err) {
			console.log(err)
		} finally {
			setLoadState((prev) => ({ ...prev, loadingTxData: false }))
		}
	}

	// export excel transaction history data

	const exportTxData = () => {
		if (txDataForExport) {
			const headings = [
				[
					'transaction date',
					'from address',
					'to address',
					'value',
					'token name',
					'token symbol',
					'contract address',
					'transaction type',
					'transaction hash',
				],
			]

			const wb = utils.book_new()
			const ws = utils.json_to_sheet([])
			utils.sheet_add_aoa(ws, headings)
			utils.sheet_add_json(ws, txDataForExport.result, {
				origin: 'A2',
				skipHeader: true,
			})
			utils.book_append_sheet(wb, ws, 'Transaction')
			const current_date = new Date()
			writeFile(wb, `${current_date}.xlsx`)
		}
	}


	return (
		<AccountContext.Provider 
			value={{
				loadState,
				chartData,
				accountInfo,
				txHistoryData,
				loadAccountData,
				loadTxHistoryData,
				exportTxData,
				loadAccount
			}}
		>
			{children}
		</AccountContext.Provider>
	)
}

export const useAccountInfo = () => useContext(AccountContext)