import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
import { Chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { bsc, bscTestnet } from 'wagmi/chains'
import _ from 'lodash'
import { getContractsData } from 'utils/contracts'

const network = process.env.NETWORK || 'testnet'

const chains: Chain[] = (network == 'testnet')?[bscTestnet]:[bsc]

// Wagmi client
const { provider } = configureChains(chains, [
	walletConnectProvider({ projectId: process.env.REACT_APP_PROJECT_ID || '' }),
])

const wagmiClient = createClient({
	autoConnect: true,
	connectors: modalConnectors({ 
		appName: 'FSP',
		chains,
		projectId: process.env.REACT_APP_PROJECT_ID || ''
	}),
	provider
})

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains)

export { wagmiClient, ethereumClient }
