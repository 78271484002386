import React, { useEffect } from 'react'
import { disclaimersInfo } from '../../constants'
import useAuth from 'rftxStore/auth/auth.hook'
import BackToMain from '../BackToMain'
import './Disclaimers.css'

const Disclaimers = () => {
	const { setHeaderType } = useAuth()
	useEffect(() => {
		setHeaderType(4)
	}, [])

	return (
		<div className="disclaimers-page w-100 d-center position-relative">
			<BackToMain />
			<div className="disclaimers-main">
				<label className="disclaimers-title text-center w-100">
          Legal Disclaimer
				</label>
				<div className="d-flex justify-content-center w-100">
					<div className="fee-items py-5 text-left">
						{disclaimersInfo.map((item, index) => {
							return (
								<div key={index}>
									<div
										className="item-title"
										dangerouslySetInnerHTML={{ __html: item.title }}
									></div>
									<div
										className="item-description pt-2"
										dangerouslySetInnerHTML={{ __html: item.description }}
									></div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Disclaimers
