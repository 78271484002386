import React, { useState } from 'react'
import { Form, Button, Row, Col, InputNumber, Space, Typography } from 'antd'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { roundBigNum, roundStr } from 'utils'
import Compact from 'antd/es/space/Compact'
import { getTokenBalance } from 'web3Client/account'
import { useSigner } from 'wagmi'
import ButtonGroup from 'antd/es/button/button-group'
import { depositTokens } from 'web3Client/pool'

const { Text } = Typography

interface Props {
	token:{
		address:string,
		symbol:string
	},
	onFinishForm:( amount:number )=>void;
}

const StakeForm = ({ token, onFinishForm }: Props) => {

	const [ form ] = Form.useForm()

	const [isLoading, setIsLoading] = useState(false)
	const { data: signer } = useSigner()

	const [tokenBalance, setTokenBalance] = useState(0)

	const onFinish = async (values:any) => {
		await onFinishForm(values.deposit_amount)
		await fetchTokenBalance()
	}

	const fetchTokenBalance = async () => {
		setIsLoading(true)
		const tokenBalance = await getTokenBalance(token.address, signer)
		setTokenBalance(tokenBalance)
		setIsLoading(false)
	}

	useEffect(()=>{
		fetchTokenBalance()
	}, [token, signer])

	const depositAmount = (percentage:any) => {
		form.setFieldValue('deposit_amount', Math.round(percentage * tokenBalance / 100))

	}

	return (
		<div className='w-100 mt-1'>
			<Form form = {form} onFinish={onFinish}>
				<Row>
					<Col md={6}><Text>My Wallet</Text></Col>
					<Col md={18} className='text-end'>
						<NumberFormat value={tokenBalance} displayType={'text'} thousandSeparator={true} /><Text> {token.symbol}</Text>
					</Col>
				</Row>
				<hr className='bg-success m-2'/>
				<Row align="middle" gutter={[4, 4]}>
					<Col md={20}>
						<div className='d-flex align-items-end'>
							<Form.Item name = "deposit_amount" className='mb-0 flex-grow-1'>
								<InputNumber
									className='w-100 border-success'
									// type='number' 
									size='large'
									formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
							<div className='ms-1' style={{right:0}}>Qty</div>
						</div>
					</Col>
					<Col md={4}>
						<Button htmlType='submit' type='primary' className='border-btn h-100 w-100 d-flex justify-content-center'>Stake</Button>
					</Col>
					<Col md={24}>
						<Space>
							<Button style={{ minWidth:'max-content'}} onClick={()=>depositAmount(25)}> 25%</Button>
							<Button style={{ minWidth:'max-content'}} onClick={()=>depositAmount(50)}> 50%</Button>
							<Button style={{ minWidth:'max-content'}} onClick={()=>depositAmount(75)}> 75%</Button>
							<Button style={{ minWidth:'max-content'}} onClick={()=>depositAmount(100)}> 100%</Button>
						</Space>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default React.memo(StakeForm)