import { Spin } from 'antd'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { validateAddress } from '../../utils'
import './AdminOverViewInfo.css'

const AdminOverViewInfo = (props) => {
	const { summaryData, checkHolderLookup, holderLookup, loadState } = props
	const [searchAddress, setSearchAddress] = useState('')

	// const handleLiquidate = async () => {
	//   try {
	//     liquidate();
	//   } catch (err) {
	//     toast.error(err.code);
	//   }
	// };

	const searchHolderLookup = () => {
		if (validateAddress(searchAddress.trim())) {
			checkHolderLookup(searchAddress)
		}
	}

	return (
		<div className="AdminOverViewPage container">
			<div className="row w-100 admin-layout">
				<div
					className="col-xl"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div className="RFXTokenSummary">
						<label id="title">RFX Token Summary</label>
						<div className="w-100 d-flex justify-content-between">
							<span>MarketCap</span>{' '}
							<span>
                $
								<NumberFormat
									value={summaryData.marketcap}
									displayType={'text'}
									thousandSeparator={true}
								/>
							</span>
						</div>
						<div className="d-flex flex-column">
							<div>
								<span>Total Holders</span>
								<span>
									<NumberFormat
										value={summaryData.holders}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
							<div>
								<span>Average tokens held</span>
								<span>
									<NumberFormat
										value={summaryData.avgTokenHeld}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
						</div>
						<div className="d-flex flex-column">
							<div>
								<span>Total dividends distributed</span>
								<span>
                  $
									<NumberFormat
										value={summaryData.totalDividendsDistributed}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
							<div>
								<span>Total partition distributed</span>
								<span>
                  $
									<NumberFormat
										value={summaryData.totalPartitionDistributed}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
						</div>
						<div className="d-flex flex-column">
							<div>
								<span>Total 401k deposited</span>
								<span>
									<NumberFormat
										value={summaryData.total401kInfo.total401kDeposit}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
							<div>
								<span>Total 401k match</span>
								<span>
									<NumberFormat
										value={summaryData.total401kInfo.total401kMatch}
										displayType={'text'}
										thousandSeparator={true}
									/>
								</span>
							</div>
							{/* <div>
                <span>Total next 401k payout</span>
                <span>0</span>
              </div> */}
						</div>
						{/* <button className="liquidateBtn" onClick={handleLiquidate}>
                Liquidate Admin Contract
              </button> */}
						<div className="d-flex w-100 justify-content-between">
							<span>Standard Div Index</span>
							<span>{summaryData.standardDivIndex}</span>
						</div>
					</div>
				</div>
				<div className="HolderLookup col-xl justify-content-center align-items-center">
					<label className="font-title">Holder Lookup</label>
					<div className="HolderLookupCard">
						<input
							className="HolderAddress"
							placeholder="Holder Address"
							onChange={(e) => setSearchAddress(e.target.value)}
						/>
						<button
							id="search"
							onClick={() => {
								searchHolderLookup()
							}}
						>
              Search
						</button>

						{loadState.loadHolderData ? (
							<span className="load-holder">
								<Spin />
							</span>
						) : (
							<>
								<div>
									<span>Tokens held</span>
									<span>
										<NumberFormat
											value={holderLookup.tokenHeld}
											displayType={'text'}
											thousandSeparator={true}
										/>
									</span>
								</div>
								{/* <div className="d-flex flex-column">
									<div>
										<span>Total dividends received</span>
										<span>${holderLookup.reflection.earned}</span>
									</div>
								</div> */}
								{/* <div>
									<span>Total Partition received</span>
									<span>${holderLookup.partitionEarned}</span>
								</div> */}
								{/* <div className="d-flex flex-column">
									<div>
										<span>Total 401k deposited</span>
										<span>${holderLookup.total401kDeposit}</span>
									</div>
									<div>
										<span>Total 401k match</span>
										<span>${holderLookup.total401kMatch}</span>
									</div>
									<div>
										<span>401k Lifetime(days)</span>
										<span>{holderLookup.feeTimer}</span>
									</div>
								</div> */}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdminOverViewInfo
