import { Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import useAuth from 'rftxStore/auth/auth.hook'
import './Withdrawal.css'
import { useAlertError } from '../../hooks/useAlertError'
import toast from 'react-hot-toast'
import BackToMain from '../BackToMain'
import { Link } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import apiConfig from 'api/config'

// eslint-disable-next-line no-undef
const BACKEND_URL = `${apiConfig.host}/api/v1`

const { TextArea } = Input

const Withdrawal = () => {
	const { address } = useAccount()
	const { setHeaderType } = useAuth()
	const [email, setEmail] = useState('')
	const [addressForSubmit, setAddressForSubmit] = useState('')
	const [reason, setReason] = useState('')
	const alertError = useAlertError()

	useEffect(() => {
		setHeaderType(1)
	}, [])

	const submit = async () => {
		try {
			if (address && address.toLowerCase() == addressForSubmit.toLowerCase()) {
				const { data } = await axios.post(
					`${BACKEND_URL}/withdrawal/newEvent`,
					{
						address: address,
						email: email,
						reason: reason,
					}
				)
				if (data) {
					toast.success('Submitted successfully!')
					setEmail('')
					setReason('')
					setAddressForSubmit('')
				}
			} else {
				alertError(
					new Error('The wallet address does not match the connected wallet.')
				)
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<div className="position-relative w-100 withdrawal-back-btn">
				<BackToMain />
				<div className="withdrawal-page d-flex justify-content-center container">
					<div className="withdrawal-main row gap-2">
						<div className="col-12 col-md">
							<div>
								<label>Email</label>
								<Input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div>
								<label>Wallet Address</label>
								<Input
									value={addressForSubmit}
									onChange={(e) => setAddressForSubmit(e.target.value)}
								/>
							</div>
							<div>
								<label>Reason for Withdrawal</label>
								<TextArea
									rows={4}
									value={reason}
									onChange={(e) => setReason(e.target.value)}
								/>
							</div>
							<button
								className="withdrawal-submit mt-2"
								onClick={() => submit()}
							>
                Submit
							</button>
						</div>
						<div className="col-12 col-md">
							<label className="pt-2">
                Please contact RFX to withdraw your 401k. We will endeavor to
                complete this for you as soon as possible. Ensure you have read
                the terms and conditions{' '}
								<Link to="/disclaimers" style={{ color: 'blue' }}>
                  Here
								</Link>{' '}
                and are aware of possible penalties.
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Withdrawal
