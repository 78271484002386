import React, { useState } from 'react'
import { useAccountInfo } from './AccountProvider'
import ReactApexCharts from 'react-apexcharts'
import { Spin, Table, DatePicker, Button } from 'antd'
import { ApexOptions } from 'apexcharts'
import NumberFormat from 'react-number-format'

const { RangePicker } = DatePicker

const  TransactionHistorySection = () => {

	const { loadState, chartData, txHistoryData, exportTxData, loadTxHistoryData }  = useAccountInfo()

	const [explorerUrl, setExporlerUrl] = useState('https://bscscan.com')

	// set react apex chart opention

	const series = chartData ? chartData : []

	const options:ApexOptions = {
		chart: {
			id: 'area-datetime',
			type: 'area',
			height: 350,
			zoom: {
				autoScaleYaxis: true,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: 2,
		},
		markers: {
			size: 0,
			// style: 'hollow',
		},
		xaxis: {
			type: 'datetime',
			tickAmount: 6,
		},
		tooltip: {
			x: {
				format: 'dd MMM yyyy',
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 100],
			},
		},
	}

	// set columns of transactions history data

	const columns = [
		{
			title: 'Txn Hash',
			dataIndex: 'txnhash',
			key: 'txnhash',
			width: '16.6%',
			textWrap: 'word-break',
			render: (text:string) => (
				<a href={`${explorerUrl}/tx/${text}`} target={'_blank'} rel="noreferrer">
					{String(text).slice(0, 8)}...
				</a>
			),
		},
		{
			title: 'Date',
			dataIndex: 'age',
			key: 'age',
			width: '16.6%',
			textWrap: 'word-break',
		},
		{
			title: 'From',
			dataIndex: 'from',
			key: 'from',
			width: '16.6%',
			textWrap: 'word-break',
			render: (data:any) =>
				data.type ? (
					<a
						href={`${explorerUrl}/address/${data.address}`}
						target={'_blank'} rel="noreferrer"
					>
						{String(data.address).slice(0, 8)}...
					</a>
				) : (
					String(data.address).slice(0, 8) + '...'
				),
		},
		{
			title: 'To',
			dataIndex: 'to',
			key: 'to',
			width: '16.6%',
			textWrap: 'word-break',
			render: (data:any) =>
				data.type ? (
					<a
						href={`${explorerUrl}/address/${data.address}`}
						target={'_blank'} rel="noreferrer"
					>
						{String(data.address).slice(0, 8)}...
					</a>
				) : (
					String(data.address).slice(0, 8) + '...'
				),
		},
		{
			title: 'Value',
			dataIndex: 'value',
			key: 'value',
			width: '40px',
			textWrap: 'word-break',
			render: (value:any) => (
				<div
					style={{
						wordWrap: 'break-word',
						wordBreak: 'break-word',
						width: '40px',
					}}
				>
					<NumberFormat
						value={value}
						displayType={'text'}
						thousandSeparator={true}
					/>
				</div>
			),
		},
		{
			title: 'Token',
			dataIndex: 'token',
			key: 'token',
			width: '16.6%',
			textWrap: 'word-break',
			render: (data:any) => (
				<a
					href={`${explorerUrl}/address/${data.address}`}
					target={'_blank'} rel="noreferrer"
				>
					{data.symbol}
				</a>
			),
		},
	]

	const changeTxDataDate = async (dateValues:any) => {
		const from_date = Math.floor(dateValues[0].valueOf() / 1000)
		const to_date = Math.floor(dateValues[1].valueOf() / 1000)
		loadTxHistoryData(from_date, to_date)
	}


	return (
		<div className="mt-5 text-center">
			<div>
				<label className="fs-2 my-3">Account Chart</label>
				<div>
					{loadState.loadingChart ? (
						<div style={{ padding: '80px 0px' }}>
							<Spin />
						</div>
					) : (
						<ReactApexCharts
							options={options}
							series={series}
							type="area"
							height={350}
						/>
					)}
				</div>
			</div>
			<div className="mt-5">
				<label className="fs-2 my-3">
					Recent platform transaction history
				</label>
				<div className="w-100">
					<Table
						dataSource={txHistoryData}
						columns={columns}
						pagination={{
							pageSize:10
						}}
						loading={loadState.loadingTxData}
						sticky
					/>
					<div className="">
						<RangePicker
							bordered={true}
							showTime
							onChange={changeTxDataDate}
						/>
						<Button
							type ="primary"
							className="border-btn m-auto mt-3"
							onClick={() => exportTxData()}
						>
										Download History
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TransactionHistorySection