import { useEffect } from 'react'
import AdminStakingPool from '../../components/AdminStakingPool'
import useAuth from 'rftxStore/auth/auth.hook'


const AdminStaking = () => {
	const { setSidebarType } = useAuth()

	useEffect(() => {
		setSidebarType(4)
	}, [])

	return (
		<AdminStakingPool />
	)
}

export default AdminStaking
