import React, { useEffect } from 'react'
import { Layout as AntdLayout } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import InvestorsSidebar from 'components/InvestorsSidebar'
import HeaderNavbar from 'components/HeaderNavbar'
import 'components/HeaderNavbar/Header.css'
import useAuth from 'rftxStore/auth/auth.hook'
import { useAccount, useNetwork, useBalance } from 'wagmi'
import { watchAccount } from 'wagmi/actions'

import { theme, Grid } from 'antd'
import { useAccountInfo } from 'hooks/AccountInfoProvider'

const { Content } = AntdLayout
const { useBreakpoint } = Grid

const Layout = (props:any) => {


	const { auth:{sidebarToggle, sidebarType, headerType, contractsLoaded } } = useAuth()
	const { accountInfo, isLoading } = useAccountInfo()
	const screens = useBreakpoint()
	const { xs } = screens
	let adminSidebar = false
	useEffect(() => {
		if (sidebarType === 3 || sidebarType === 4) {
			adminSidebar = true
		}
	}, [sidebarType])

	const navigate = useNavigate()
	const { address, isConnected } = useAccount()
	const { chain } = useNetwork()
	console.log('chain ==>', chain)

	watchAccount((account) => {
		console.log('-- account is changed --')
		console.log('old account:', address)
		console.log('new account:', account.address)
		// if(address != account.address){
		// 	localStorage.removeItem('api-signature')
		// }
	})
	console.log('process.env.REACT_APP_LOGIN_TOKEN_BALANCE ==>', process.env.REACT_APP_LOGIN_TOKEN_BALANCE)
	useEffect(
		() => {
			console.log('isConnected ==>', isConnected)
			if (!isConnected) {
				navigate('/auth')
				return
			}
			if(!isLoading && accountInfo.tokenBalance < Number(process.env.REACT_APP_LOGIN_TOKEN_BALANCE)){
				// 
				navigate('/deposit')
				return
			}
		}, 
		[address, isConnected, accountInfo.tokenBalance, isLoading]
	)


	return (
		<>
			<AntdLayout>
				{headerType === 0 ? (
					<>
						<HeaderNavbar headerType={0} />
						{!xs &&  <InvestorsSidebar type={`${adminSidebar ? 'admin' : ''} `} />}
						{
							contractsLoaded && 
							<Content className={`${sidebarToggle && 'ps-0'} SidebarToggle1`} style={{ paddingLeft:sidebarToggle?'0px':xs?'0px':'270px'}}>
								<Outlet />
							</Content>
						}
					</>
				) : (
					<>
						<HeaderNavbar headerType={headerType} />
						{
							contractsLoaded  && <Content className="SidebarNone"><Outlet /></Content>
						}
					</>
				)}
			</AntdLayout>
		</>
	)
}

export default Layout
