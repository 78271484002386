import { Table, Popconfirm } from 'antd'
import React from 'react'

const WithdrawalRequest = ({
	requestData,
	handleRequestDelete,
	loadingRequest,
}) => {
	const columns = [
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			textWrap: 'word-break',
		},
		{
			title: 'Wallet Address',
			dataIndex: 'walletAddress',
			key: 'walletAddress',
			textWrap: 'word-break',
		},
		{
			title: 'Reason',
			dataIndex: 'reason',
			key: 'reason',
			textWrap: 'word-break',
		},
		{
			title: 'Request Date',
			dataIndex: 'requestDate',
			key: 'requestDate',
			textWrap: 'word-break',
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			render: (key) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => handleRequestDelete(key)}
				>
					<a style={{ color: 'blue' }}>Delete</a>
				</Popconfirm>
			),
		},
	]
	return (
		<Table
			className="RequestViewTable"
			dataSource={requestData}
			columns={columns}
			pagination={false}
			loading={loadingRequest}
			style={{ height: 200 }}
			scroll={{ x: 600, height: 200 }}
			sticky
		/>
	)
}

export default WithdrawalRequest
