import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import { Tooltip } from 'antd'
import Sider from 'antd/lib/layout/Sider'

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useAlertError } from '../../hooks/useAlertError'
import useAuth from 'rftxStore/auth/auth.hook'
import './InvestorsSidebar.css'
import { isAdmin } from 'api/api-users'

// eslint-disable-next-line no-undef
const REACT_APP_OWNER = process.env.REACT_APP_OWNER || ''

const InvestorsSidebar = (props) => {
	const { auth:{sidebarType, sidebarToggle},  setSidebarType, setSidebarToggle } = useAuth()
	const { isConnected, address } = useAccount()

	const navigate = useNavigate()
	const alertError = useAlertError()

	const toAdmin = async () => {
		try {
			if (!isConnected) {
				throw new Error('Please connect Wallet')
			}

			const { data } = await isAdmin({ address })

			if (data.result == false) {
				throw new Error('You are not admin')
			}
			setSidebarType(4)
			navigate('/stakingpool')
		} catch (err) {
			alertError(new Error(err.message))
			navigate('/account', { replace: false })
		}
	}

	return (
		<>
			<Sider 
				className={`InvestorSidebarSection ${
					sidebarToggle ? 'InvestSidebarToggle' : null
				}`}
				style={{ backgroundColor: '#0ca788' }}
			>
				<div
					className={`SidebarItems ${
						sidebarType !== 3 && sidebarType !== 4 && sidebarType !== 7
							? 'd-flex'
							: null
					}`}
				>
					<div
						className={`SidebarItem ${
							sidebarType === undefined || sidebarType === 0
								? 'SidebarItemActive'
								: null
						}`}
					>
						<Link to="/account" onClick={() => setSidebarType(0)}>Account</Link>
					</div>
					<div
						className={`SidebarItem ${
							sidebarType === 2 ? 'SidebarItemActive' : null
						}`}
					>
						<Link to="/staking" onClick={() => setSidebarType(2)}>Staking</Link>
					</div>

					<div
						className={`SidebarItem ${
							sidebarType === 5 ? 'SidebarItemActive' : null
						}`}
					>
						<Tooltip title="Coming Soon">
							<button className='disable-sidebar-btn'>Lending</button>
						</Tooltip>
					</div>

					<div
						className={`SidebarItem ${
							sidebarType === 6 ? 'SidebarItemActive' : null
						}`}
					>
						<Tooltip title="Coming Soon">
							<button className='disable-sidebar-btn'>Borrowing</button>
						</Tooltip>
					</div>

					<div className="SidebarTogglePart">
						<FaArrowLeft onClick={() => setSidebarToggle(true)} />
					</div>

					<div className="sidebar-absolute-btns">
						<button
							className="DisclaimerBtn w-100"
							onClick={() => {
								toAdmin()
							}}
						>Admin Login</button>

						<Link className="AdminLoginBtn" to={'/disclaimers'}>Disclaimers</Link>

						<Link to="/fees" className="PlatformFees">Platform Fees</Link>
					</div>
				</div>

				<div
					className={`SidebarAdminItems ${
						sidebarType === 3 || sidebarType === 4 || sidebarType === 7
							? 'd-flex'
							: null
					}`}
				>
					<div
						className={`SidebarItem ${
							sidebarType === 4 ? 'SidebarItemActive' : null
						}`}
					>
						<Link to="/stakingpool" onClick={() => setSidebarType(4)}>Staking pools</Link>
					</div>
					{address && address.toLowerCase() === REACT_APP_OWNER.toLowerCase() && (
						<>
							<div
								className={`SidebarItem ${
									sidebarType === 3 ? 'SidebarItemActive' : null
								}`}
							>
								<Link to="/admin" onClick={() => setSidebarType(3)}>Overview</Link>
							</div>

							<div
								className={`SidebarItem ${
									sidebarType === 7 ? 'SidebarItemActive' : null
								}`}
							>
								<Link to="/requests" onClick={() => setSidebarType(7)}>Withdrawal requests</Link>
							</div>
						</>
					)}

					<div className="SidebarTogglePart">
						<FaArrowLeft onClick={() => setSidebarToggle(true)} />
					</div>
					<Link to="/account" className="moveInvestor">Go to Investors</Link>
				</div>
			</Sider>
			<FaArrowRight
				className={`SidebarToggleOpenBtn position-fixed ${
					!sidebarToggle ? 'd-none' : null
				}`}
				onClick={() => setSidebarToggle(false)}
			/>
		</>
	)
}

export default InvestorsSidebar
