import { ContractsMapType } from './types'

let contracts:ContractsMapType

export const setContracts = (_contracts:ContractsMapType) => {
	contracts = _contracts
}

export const getContractsData = () : ContractsMapType => {
	return contracts
}