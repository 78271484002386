import React, { useEffect } from 'react'
import { utilitiesInfo } from '../../constants'
import useAuth from 'rftxStore/auth/auth.hook'
import BackToMain from '../BackToMain'
import './Utilities.css'

const Utilities = () => {
	const { setHeaderType } = useAuth()

	useEffect(() => {
		setHeaderType(2)
	}, [])

	return (
		<div className="utilities-page px-5">
			<div className="utilities-main w-100 d-col-center position-relative">
				<BackToMain />
				<label className="utilities-title">Rematic Finance Utilities</label>
				<div className="utilites-items py-5">
					{utilitiesInfo.map((item, index) => {
						return (
							<div key={index}>
								<div
									className="item-title"
									dangerouslySetInnerHTML={{ __html: item.title }}
								></div>
								<div
									className="item-description pt-2"
									dangerouslySetInnerHTML={{ __html: item.description }}
								></div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Utilities
