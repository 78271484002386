
import { getRequest, formPostRequest, putRequest } from './api-requests'
import config from './config'
// eslint-disable-next-line no-undef
const REACT_APP_CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 97

export const createPool = (payloads) => formPostRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/`, payloads)
export const getPoolDetail = (pool) => getRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/${pool}`)
export const getAllPools = () => getRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}`)
export const getMyPools = (address) => getRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/owner/${address}`)
export const updatePoolLiveData = (pool) => putRequest(`${config.host}/api/v1/pools/${REACT_APP_CHAIN_ID}/${pool}`)
