import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { AccountInfoType } from 'utils/types'

import { getContracts } from 'api/api-rftx'
import { getContractsData, setContracts } from 'utils/contracts'
import { useAccount, useSigner } from 'wagmi'
import useAuth from 'rftxStore/auth/auth.hook'
import { getRFXStakedAmount, getTokenBalance } from 'web3Client/account'
import { createGenericContext } from 'utils/createGenericContext'

export type AccountProviderContextType = {
    isLoading: boolean;
    accountInfo: AccountInfoType;
    loadAccountInfo: ()=>void;
};

// export const AccountInfoContextProvider = createGenericContext<AccountProviderContextType>()
const [useAccountInfo, AccountInfoContextProvider] =
  createGenericContext<AccountProviderContextType>()

export const AccountInfoProvider = (props:any)=>{

	const { data: signer } = useSigner()
	const { address } = useAccount()
	const { auth: { contractsLoaded }, setContractsLoaded } = useAuth()

	const [isLoading, setIsLoading] = useState(true)
	const [accountInfo, setAccountInfo] = useState<AccountInfoType>({
		totalBalance: 0,
		tokenValue: 0,
		tokenBalance: 0,
		mainTokenValue: 0,
		stakingBalance: 0,
	})

	useEffect(()=>{
		getContracts().then((res)=>{
			setContracts(res.data)
			setContractsLoaded(true)
		})
	}, [])

	useEffect(()=>{
		loadAccountInfo()
	}, [contractsLoaded, address, signer])

	const loadAccountInfo = () => {
		if(contractsLoaded && address && signer){
			loadAccountData()
		}else{
			setAccountInfo({
				totalBalance: 0,
				tokenValue: 0,
				tokenBalance: 0,
				mainTokenValue: 0,
				stakingBalance: 0,
			})
		}
	}

	const loadAccountData = useCallback(async ()=>{
		setIsLoading(true)
		const contractMap = getContractsData()
		const tokenBalance = await getTokenBalance(contractMap.rematicFinance.address, signer)
		// const reflection = await getReflectionsFromDefaultDivTracker(signer)
		// const partitionEarned = await getPartitionEarned(signer)
		const stakingBalance = await getRFXStakedAmount(signer)
		// const four01kValue = await get401kReward(signer)
		setAccountInfo({ ...accountInfo,  tokenBalance, stakingBalance })
		setIsLoading(false)
	}, [address, signer])

	return (
		<AccountInfoContextProvider
			value={{
				accountInfo,
				isLoading,
				loadAccountInfo:loadAccountInfo
			}}
		>
			{props.children}
		</AccountInfoContextProvider>
	)
}

export { useAccountInfo }